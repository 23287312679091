<template>
  <div class="recycle-container">
    <el-table :data="recycleList" style="width: 100%">
      <el-table-column label="应用名称/表单名称" align="left">
        <template slot-scope="scope">
          <span v-if="scope.row.type == '1'" class="projectTag">
            <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/project-icon.png">
          </span>
          <span v-else class="formTag">
            <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-icon.png">
          </span>
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
      <el-table-column prop="deleteTime" label="删除时间" align="center"></el-table-column>
      <el-table-column label="操作" width="180" align="center" style="height: auto">
        <template slot-scope="scope">
          <el-button type="text" @click="onRestoreClick(scope.row)">还原</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :pageSizes.sync="pageSizes" @pagination="onTrashList"/>

  </div>
</template>

<script>
import { trashList, httpTrash } from "@/api/system/user";

export default {
  props: {

  },
  data() {
    return {
      recycleList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      pageSizes:[5,10,15,20]
    }
  },
  created() {
    this.getRecycleList()
  },
  methods: {
    getRecycleList() {
      let _this = this
      trashList(_this.queryParams).then(response => {
        if (response.code == 200) {
          _this.recycleList = response.rows
          _this.total = response.total
        }
      })
    },
    onTrashList(data) {
      this.queryParams = {
        pageNum: data.page,
        pageSize: data.limit,
      }
      this.getRecycleList()
    },
    // 还原操作
    onRestoreClick(scope) {
      let _this = this
      let trashId = scope.trashId
      let type = scope.type
      let typeMsg = type == 1 ? '确认还原该应用？' : '确认还原该表单？'
      _this.$confirm(typeMsg, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return httpTrash(trashId)
      }).then(() => {
        _this.$message({
          message: '还原成功',
          type: 'success'
        })
        _this.getRecycleList()
      }).catch(function() {})
    }
  }
};
</script>
<style lang="scss" scoped>
  .recycle-container{
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    .projectTag{
      margin-right: 10px;
      img {
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }
    }
    .formTag {
      margin-right: 10px;
      img {
        display: inline-block;
        width: 25px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }
</style>
