<template>
  <div class="app-user-info" v-cloak>
    <div class="team-container">
      <div class="team-info-detail">
        <div class="fx-row-info-panel">
          <div class="label">用户头像</div>
          <div class="content">
            <div class="headPortrait-group--data" @click="editCropper">
              <div class="headPortrait-group--hover"></div>
              <img :src="option.img ? option.img : 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/profile.jpg'" title="" class="img-circle img-lg" style="width: 60px; height: 60px;" />
            </div>
            <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
              <div class="cropper-content">
                <div class="cropper-box">
                  <div class="cropper">
                    <vue-cropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType" :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight"
                                 :fixed="option.fixed" :fixedNumber="option.fixedNumber" :full="option.full" :fixedBox="option.fixedBox" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :centerBox="option.centerBox" :height="option.height"
                                 :infoTrue="option.infoTrue" :maxImgSize="option.maxImgSize" :enlarge="option.enlarge" :mode="option.mode" @realTime="realTime" @imgLoad="imgLoad">
                    </vue-cropper>
                  </div>
                  <!--底部操作工具按钮-->
                  <div class="footer-btn">
                    <div class="scope-btn">
                      <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload">
                        <el-button size="small" @click="initConfig" style="margin-right: 10px">上传<i class="el-icon-upload el-icon--right"></i></el-button>
                      </el-upload>
                      <el-button size="mini" type="danger" plain icon="el-icon-zoom-in" @click="changeScale(1)">放大</el-button>
                      <el-button size="mini" type="danger" plain icon="el-icon-zoom-out" @click="changeScale(-1)">缩小</el-button>
                      <el-button size="mini" type="danger" plain @click="rotateLeft">↺ 左旋转</el-button>
                      <el-button size="mini" type="danger" plain @click="rotateRight">↻ 右旋转</el-button>
                    </div>
                    <div class="upload-btn">
                      <el-button size="mini" type="success" @click="uploadImg">确定上传</el-button>
                    </div>
                  </div>
                </div>
                <!--预览效果图-->
                <div class="show-preview">
                  <div class="preview">
                    <img :src="previews.url" :style="previews.img">
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
        <div class="fx-row-info-panel" v-if="agencyUserData">
          <div class="label">姓名</div>
          <div class="content">
            <span class="content-row">{{ agencyUserData.name ? agencyUserData.name : '暂未设置' }}</span>
            <!-- <el-button type="text" class="x-button" @click="onEditUserInfo">修改</el-button> -->
          </div>
        </div>
        <div class="fx-row-info-panel" v-if="agencyUserData">
          <div class="label">编号</div>
          <div class="content">
            <span class="content-row">{{ agencyUserData.number ? agencyUserData.number : '暂未设置' }}</span>
            <!-- <el-button type="text" class="x-button" @click="onEditUserInfo">修改</el-button> -->
          </div>
        </div>
        <div class="fx-row-info-panel" v-if="agencyUserData.agencyUserDeptNames">
          <div class="label">所在部门</div>
          <div class="content">
            <span class="content-row">{{ agencyUserData.agencyUserDeptNames.join() }}</span>
            <!-- <el-button type="text" class="x-button" @click="onEditUserInfo">修改</el-button> -->
          </div>
        </div>
        <div class="fx-row-info-panel" v-if="agencyUserData.agencyUserRoleNames">
          <div class="label">角色</div>
          <div class="content">
            <span class="content-row">{{ agencyUserData.agencyUserRoleNames.join() }}</span>
            <!-- <el-button type="text" class="x-button" @click="onEditUserInfo">修改</el-button> -->
          </div>
        </div>
        <div class="fx-row-info-panel" v-if="!agencyUserData">
          <div class="label">昵称</div>
          <div class="content">
            <span class="content-row">{{ userInfo.nickName ? userInfo.nickName : '暂未设置' }}</span>
            <!-- <el-button type="text" class="x-button" @click="onEditUserInfo">修改</el-button> -->
          </div>
        </div>
        <!-- <div class="fx-row-info-panel" v-if="!agencyUserData">
            <div class="label">用户性别</div>
            <div class="content">
              <span class="content-row">{{ userInfo.sex == 1 ? '男' : (userInfo.sex == 2 ? '女' : '未知')}}</span>
              <el-button type="text" class="x-button" @click="onEditUserInfo">修改</el-button>
            </div>
          </div> -->

        <!-- <div class="fx-row-info-panel">
          <div class="label">用户 UID</div>
          <div class="content">
            <span class="content-row">{{ userInfo.uid ? userInfo.uid : '暂未设置' }}</span>
          </div>
        </div> -->
        <div class="fx-row-info-panel">
          <div class="label">登录账号</div>
          <div class="content">
            <span class="content-row">{{userInfo.mobile ? userInfo.mobile : (userInfo.email ? userInfo.email : userInfo.nickName)}}</span>
          </div>
        </div>
        <div class="fx-row-info-panel">
          <div class="label">账号类型</div>
          <div class="content">
            <!-- 用户身份信息 是否管理员 -->
            <span class="content-row">{{userInfo.type == '1' ? '普通用户' : ( userInfo.type == '2' ? '机构管理员' : '机构子管理员')}}</span>
          </div>
        </div>
        <div class="fx-row-info-panel" v-for="(widget,index) in userExtendProfile" :index="index">
          <div class="label">{{widget.options.label}}</div>
          <div class="content">
            <!-- 用户身份信息 是否管理员 -->
            <span class="content-row">{{agencyUserData.extendField[widget.options.name]}}</span>
            <el-button type="text" class="x-button" @click="onEditUserInfo">修改</el-button>
          </div>
        </div>
        <!-- <div class="fx-row-info-panel">
          <div class="label">微信账号</div>
          <div class="content">
            <el-button type="text" class="x-button" style="padding-left:0px" @click="unbindWechat" v-if="userInfo.openId">解绑</el-button>
            <el-button type="text" class="x-button" style="padding-left:0px" @click="bindWechat" v-else>绑定</el-button>
          </div>
        </div> -->
        <el-dialog title="修改信息" :visible.sync="infoOpen" width="640px" append-to-body @close="onCloseEdit">
          <el-form ref="projectForm" :model="form" :rules="formRules" label-width="120px" label-position="right" @submit.native.prevent>
            <el-form-item label="用户 UID" prop="nickName">
              <el-input v-model="form.uid" placeholder="请输入用户 UID" disabled />
            </el-form-item>
            <!-- <el-form-item label="用户昵称" prop="nickName">
              <el-input v-model="form.nickName" placeholder="请输入用户昵称" />
            </el-form-item> -->
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="form.sex">
                <el-radio v-model="form.sex" label="1">男</el-radio>
                <el-radio v-model="form.sex" label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="登录账号" prop="nickName">
              <el-input v-model="form.mobile" placeholder="请输入登录账号" disabled />
            </el-form-item>
            <el-form-item label="账号类型" prop="nickName">
              <el-input v-model="form.type" placeholder="请输入账号类型" disabled />
            </el-form-item>
            <div class="user-extend-field-wrapper">
              <v-form-render ref="vformRender" :formJson="formJson" :formData="formData" :optionData="optionData" :previewState="false"></v-form-render>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onConfirmEdit">确 定</el-button>
            <el-button @click="onCancelEdit">取 消</el-button>
          </div>
        </el-dialog>

        <el-dialog title="绑定微信" :visible.sync="wechatBindOpen" width="640px" append-to-body @close="onCloseWeChatBind">
          <wechatBind></wechatBind>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onConfirmEdit">确 定</el-button>
            <el-button @click="onCloseWeChatBind">取 消</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import userAvatar from "./userAvatar"
import { getAgency, getAgencyUser, getAgencyUserInfo, updateAgencyUser, agencyUserList } from '@/api/system/agency'
import { updateUserInfo, userExtendFieldFormJson } from "@/api/system/user"
import { getSessionStorageObj } from "@/utils/db"
import { setOssConfig, putFile } from "@/utils/ossUtils"
import { deepClone } from '@/utils/deepClone.js'
import store from "@/store"
import { globalBus } from "@/utils/globalBus"
import { VueCropper } from "vue-cropper"

import { VXETable, Grid, Column, Button } from 'vxe-table'
import wechatBind from './wechatBind.vue'

export default {
  components: { userAvatar, VueCropper, VXETable, Grid, Column, Button, wechatBind },
  props: {},
  data () {
    return {
      userInfo: {},
      // 是否显示弹出层
      open: false,
      // 弹出层标题
      title: "修改头像",
      option: {
        img: '',             //裁剪图片的地址
        outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'jpeg',  //裁剪生成图片的格式（jpeg || png || webp）
        info: true,          //图片大小信息
        canScale: true,      //图片是否允许滚轮缩放
        autoCrop: true,      //是否默认生成截图框
        autoCropWidth: 200,  //默认生成截图框宽度
        autoCropHeight: 200, //默认生成截图框高度
        fixed: true,         //是否开启截图框宽高固定比例
        fixedNumber: [1, 1], //截图框的宽高比例
        full: false,         //false按原比例裁切图片，不失真
        fixedBox: true,      //固定截图框大小，不允许改变
        canMove: false,      //上传图片是否可以移动
        canMoveBox: true,    //截图框能否拖动
        original: false,     //上传图片按照原始比例渲染
        centerBox: false,    //截图框是否被限制在图片里面
        height: true,        //是否按照设备的dpr 输出等比例图片
        infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000,    //限制图片最大宽度和高度
        enlarge: 1,          //图片根据截图框输出比例倍数
        mode: '230px 150px'  //图片默认渲染方式
      },
      previews: {},
      file: {},
      editProfile: {
        text: '修改',
        isEdit: false,
      },
      form: {
        nickName: '',
        sex: '1'
      },
      gridOptions: {
        border: true,
        align: 'center',
        resizable: true,
        keepSource: true,
        showOverflow: true,
        loading: false,
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 50]
        },
        columns: [
          { type: 'checkbox', width: 50, fixed: 'left' },
          { field: 'name', title: '表单名称', width: 200 },
          { field: 'nickname', title: 'Nickname', width: 200 },
          { field: 'sex', title: 'Sex', width: 200 },
          { field: 'role', title: 'Role', width: 200 },
          { field: 'result', title: '审核结果', width: 200 },
          { field: 'message', title: '审核状态', width: 200 },
          { field: 'message1', title: '审核状态1', width: 200 },
          { field: 'message2', title: '审核状态2', width: 200 },
          { field: 'message3', title: '审核状态3', width: 200 },
          { field: 'message4', title: '审核状态4', width: 200 },
          { field: 'message5', title: '审核状态5', width: 200 },
          { field: 'message6', title: '审核状态6', width: 200 },
          { field: 'content', title: 'Html', type: 'html', width: 200 },
          { title: '操作', width: 200, slots: { default: 'operate' }, fixed: 'right' }
        ],
        data: []
      },
      tableToolbar: {
        buttons: [
          { code: 'myExport', name: '导出数据', type: 'text', status: 'warning' },
        ],
        custom: true
      },
      // 新版修改信息
      infoOpen: false,
      wechatBindOpen: false,

      disableForm: false,
      formJson: {},
      formData: {},
      optionData: {},
      agencyUserData: {},
      userExtendProfile: [],
      userExtendProfileData: {}
    }
  },
  computed: {},
  async created () {
    this.getUserInfo()

    this.findList()
    await this.getUserExtendField();
  },
  methods: {
    getUserInfo () {
      let self = this
      this.$store.dispatch("GetUserInfo").then((res) => {
        self.userInfo = res.data

        // agencyUserList({
        //   pageNum:1,
        //   pageSize:1
        // }).then((userList)=>{
        //     console.log('userList',userList)
        // })
        if (self.userInfo.avatarUrl) {
          self.setAvatarBase64(self.userInfo.avatarUrl, (base64) => {
            self.option.img = base64
          })
        }
      })
    },
    // 设置头像base64
    setAvatarBase64 (src, callback) {
      let _this = this;
      let image = new Image();
      // 处理缓存
      image.src = src + '?x-oss-process=image/resize,m_fixed,h_100,w_100';
      // 支持跨域图片
      image.crossOrigin = "*";
      image.onload = function () {
        let base64 = _this.transBase64FromImage(image);
        callback && callback(base64);
      }
    },
    // 将网络图片转换成base64格式
    transBase64FromImage (image) {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // 可选其他值 image/jpeg
      return canvas.toDataURL("image/png");
    },
    // 点击编辑头像
    editCropper () {
      this.open = true
      this.initConfig()
    },
    // 请求oss配置参数
    initConfig () {
      setOssConfig()
    },
    // 覆盖默认的上传行为
    requestUpload () { },
    // 上传预处理
    beforeUpload (file) {
      if (file.type.indexOf("image/") == -1) {
        this.msgError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.option.img = reader.result
          this.file = file
        }
      }
    },
    // 上传图片
    uploadImg () {
      this.$refs.cropper.getCropBlob(data => {
        let formData = new FormData()
        formData.append("avatarfile", data)
        let sessionStorageUser = getSessionStorageObj('users')
        let fileName = sessionStorageUser.userId + '/' + Date.parse(new Date()) + '_' + (this.file ? this.file.name : 'defaultTopImg')
        putFile(fileName, this.file).then(url => {
          let updateUserInfoObj = {
            avatarUrl: url,
            nickName: sessionStorageUser.nickName,
            sex: sessionStorageUser.sex ? sessionStorageUser.sex : 1
          }
          updateUserInfo(updateUserInfoObj).then(response => {
            if (response.code == 200) {
              this.open = false
              this.option.img = url
              this.msgSuccess("修改成功")
              if (this.$refs.cropper) {
                this.$refs.cropper.clearCrop()
              }
              //需要监听更改navBar头像
              globalBus.$emit('refreshUser')
            } else {
              this.$message(response.msg)
            }
          });
        });
      });
    },
    // 修改信息点击
    onEditInfoClick () {
      this.editProfile.text = '保存'
      this.editProfile.isEdit = true;
      this.form.uid = this.userInfo.uid
      this.form.nickName = this.userInfo.nickName
      this.form.sex = this.userInfo.sex
      this.form.avatarUrl = this.userInfo.avatarUrl
      this.form.type = this.userInfo.type == '1' ? '普通用户' : (this.userInfo.type == '2' ? '机构管理员' : '机构子管理员')
      this.form.mobile = this.userInfo.mobile ? this.userInfo.mobile : (this.userInfo.email ? this.userInfo.email : this.userInfo.nickName)
    },
    // 修改信息取消保存
    onCancelInfoCLick () {
      this.editProfile.text = '修改'
      this.editProfile.isEdit = false;
      this.form = {
        uid: '',
        nickName: '',
        sex: '',
        avatarUrl: '',
        type: '',
        mobile: ''
      }
    },
    // 修改信息保存
    onSaveInfoCLick () {
      updateUserInfo(this.form).then(response => {
        if (response.code == 200) {
          this.msgSuccess("编辑保存成功");
          this.editProfile.text = '修改'
          this.editProfile.isEdit = false;
          this.form = {
            uid: '',
            nickName: '',
            sex: '',
            avatarUrl: '',
            type: '',
            mobile: ''
          }
          this.getUserInfo(); //修改信息保存成功后重新请求用户信息
        } else {
          this.$message(response.msg)
        }
      });
    },
    // 新版修改信息点击
    async onEditUserInfo () {
      this.form.uid = this.userInfo.uid
      this.form.nickName = this.userInfo.nickName
      this.form.sex = this.userInfo.sex
      this.form.avatarUrl = this.userInfo.avatarUrl
      this.form.type = this.userInfo.type == '1' ? '普通用户' : (this.userInfo.type == '2' ? '机构管理员' : '机构子管理员')
      this.form.mobile = this.userInfo.mobile ? this.userInfo.mobile : (this.userInfo.email ? this.userInfo.email : this.userInfo.nickName)

      this.infoOpen = true

      await this.setUserExtendFormJson();
      await this.setUserExtendFormData(this.agencyUserData.extendField);
    },

    // 新版修改信息 确定、取消、关闭
    async onConfirmEdit () {

      let userAgencyData = await this.$refs.vformRender.getFormData();

      this.agencyUserData.extendField = userAgencyData;
      await updateAgencyUser(this.agencyUserData)


      let response = await updateUserInfo(this.form);
      if (response.code == 200) {
        this.msgSuccess("编辑保存成功");
        this.infoOpen = false
        this.form = {
          uid: '',
          nickName: '',
          sex: '',
          avatarUrl: '',
          type: '',
          mobile: ''
        }
        this.getUserInfo(); //修改信息保存成功后重新请求用户信息
      } else {
        this.$message(response.msg)
      }
    },
    onCancelEdit () {
      this.infoOpen = false
      this.form = {
        uid: '',
        nickName: '',
        sex: '',
        avatarUrl: '',
        type: '',
        mobile: ''
      }
    },
    onCloseEdit () {
      this.infoOpen = false
      this.form = {
        uid: '',
        nickName: '',
        sex: '',
        avatarUrl: '',
        type: '',
        mobile: ''
      }
    },
    // 初始化函数
    imgLoad (msg) { },
    // 图片缩放
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 向左旋转
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    // 向右旋转
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    // 实时预览函数
    realTime (data) {
      this.previews = data
    },

    findList () {
      this.gridOptions.loading = true
      setTimeout(() => {
        this.gridOptions.loading = false
        this.gridOptions.pagerConfig.total = 10
        this.gridOptions.data = [
          { id: 10001, name: 'Test1', nickname: 'T1', role: 'Develop', sex: '1', age: 28, address: 'Shenzhen', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10002, name: 'Test2', nickname: 'T2', role: 'Test', sex: '0', age: 22, address: 'Guangzhou', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10003, name: 'Test3', nickname: 'T3', role: 'PM', sex: '1', age: 32, address: 'Shanghai', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10004, name: 'Test4', nickname: 'T4', role: 'Designer', sex: '0', age: 23, address: 'Shenzhen', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10005, name: 'Test5', nickname: 'T5', role: 'Develop', sex: '0', age: 30, address: 'Shanghai', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10006, name: 'Test6', nickname: 'T6', role: 'Develop', sex: '0', age: 27, address: 'Shanghai', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10007, name: 'Test7', nickname: 'T7', role: 'Develop', sex: '1', age: 29, address: 'Shenzhen', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10008, name: 'Test8', nickname: 'T8', role: 'Develop', sex: '0', age: 32, address: 'Shanghai', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10009, name: 'Test9', nickname: 'T9', role: 'Develop', sex: '1', age: 30, address: 'Shenzhen', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: '<img height="40" src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg" style="text-align: center; margin: 3px auto">' },
          { id: 10010, name: 'Test10', nickname: 'T10', role: 'Develop', sex: '0', age: 34, address: 'Shanghai', result: '审核通过', message: '通过', message1: '通过', message2: '通过', message3: '通过', message4: '通过', message5: 'bu通过', message6: 'bu通过', content: "<img height='40' src='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg' style='text-align: center; margin: 3px auto'>" },
        ]
      }, 300)
    },
    searchEvent () {
      this.gridOptions.pagerConfig.currentPage = 1
      this.findList()
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.gridOptions.pagerConfig.currentPage = currentPage
      this.gridOptions.pagerConfig.pageSize = pageSize
      this.findList()
    },
    editRowEvent (row) {
      const $grid = this.$refs.xGrid
      $grid.setActiveRow(row)
    },
    saveRowEvent () {
      const $grid = this.$refs.xGrid
      $grid.clearActived().then(() => {
        this.gridOptions.loading = true
        setTimeout(() => {
          this.gridOptions.loading = false
          this.$XModal.message({ content: '保存成功！', status: 'success' })
        }, 300)
      })
    },
    removeRowEvent (row) {
      this.$XModal.confirm('您确定要删除该数据?').then(type => {
        const $grid = this.$refs.xGrid
        if (type === 'confirm') {
          $grid.remove(row)
        }
      })
    },
    toolbarButtonClickEvent ({ code }) {
      const $grid = this.$refs.xGrid
      switch (code) {
        case 'myExport':
          $grid.exportData({
            filename: '某表单填报数据',
            type: 'csv',
            columnFilterMethod ({ column }) { //自定义导出
              // console.log(column)
              // return ['name', 'sex'].includes(column.property)
            }
          })
          break
      }
    },
    unbindWechat () {
      this.$confirm('确实要解绑微信吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log("确定")
      }, () => {
        console.log("取消")
      })
    },
    bindWechat () {
      this.wechatBindOpen = true;
    },
    onCloseWeChatBind () {
      this.wechatBindOpen = false;
    },
    async getUserExtendField () {
      let userInfoRes = await this.$store.dispatch("GetUserInfo")
      let userInfo = userInfoRes.data;

      // if (userInfo && userInfo.adminAgencyId) {


      // }
      let currAgencyId = getSessionStorageObj('agencyId')
      let agencyRes = await getAgency(currAgencyId)
      let extendField = agencyRes.data.extendField;
      this.userExtendProfile = extendField;

      if (currAgencyId) {
        let agencyUserRes = await getAgencyUserInfo(currAgencyId)
        this.agencyUserData = agencyUserRes.data;
      }
    },
    setUserExtendFormJson () {
      let formConfig = deepClone(userExtendFieldFormJson)
      formConfig.labelWidth = 120
      formConfig.labelAlign = 'label-right-align'

      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          let formJson = {
            widgetList: this.userExtendProfile,
            formConfig: formConfig
          }

          this.$refs.vformRender.resetForm();
          this.$refs.vformRender.setFormJson(formJson);
          resolve();
        })
      })
    },
    setUserExtendFormData (data) {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          this.$refs.vformRender.setFormData(data)

          resolve();
        })
      })
    }
  }
};
</script>
<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
/*新style*/
.team-info-detail {
  margin: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e9e9e9;
  padding: 10px 0;
  .fx-row-info-panel {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    min-height: 80px;
    border-bottom: 1px solid #e9e9e9;
    margin: 0 10px;
    .label {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      width: 130px;
      text-align: center;
      background-color: #f9f9f9;
      line-height: 80px;
      color: #333333;
      font-size: 14px;
    }
    .content {
      padding: 0 20px;
      .content-row {
        line-height: 80px;
        color: #333333;
        font-size: 14px;
      }
      .x-button {
        padding: 0 20px;
        cursor: pointer;
        line-height: 80px;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
.list-group-striped {
  width: 100%;
  margin: 30px 0 60px;
  .list-group-item {
    border-top: 0;
    border-bottom: 0;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    padding: 0 0 20px 60px;
    .headPortrait-group--data {
      margin-left: 20px;
    }
    .group-item-left {
      min-width: 90px;
      text-align: right;
      font-size: 13px;
      font-weight: 400;
      color: #bababa;
      line-height: 18px;
    }
    .group-item-right {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      margin-left: 20px;
      font-size: 13px;
      font-weight: 400;
      color: #394349;
      line-height: 18px;
    }
  }
}
.headPortrait-group--data {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 10px auto;
}
.headPortrait-group--hover {
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 60px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 35px;
  color: #fff;
  cursor: pointer;
  text-align: center;
}
.headPortrait-group--hover:hover {
  background: rgba(0, 0, 0, 0.35);
}
.headPortrait-group--hover:hover::after {
  content: '修改头像';
  font-size: 12px;
}
.head-user-name {
  font-size: 13px;
  color: #394349;
  line-height: 18px;
  margin: 20px 0 0;
}
.userProfile {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  margin-top: 60px;
  .left-title {
    color: #394349;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    margin-right: 20px;
  }
  .line-box {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -o-flex: 1;
    -moz-flex: 1;
    flex: 1;
    height: 1px;
    background: #e5e5e5;
    margin-right: 20px;
  }
  .el-button--text {
    color: #bababa;
  }
}
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .cropper-box {
    flex: 1;
    width: 100%;
    .cropper {
      width: auto;
      height: 300px;
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      margin-top: 50px;
      img {
        max-width: inherit !important;
      }
    }
  }
}
.footer-btn {
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    margin-right: 10px;
  }
}

.user-extend-field-wrapper {
  ::v-deep .el-form-item__label {
    padding-right: 12px;
  }
}
</style>
