var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-user-collect" }, [
    _vm.historyData.length > 0
      ? _c(
          "div",
          { staticClass: "list-content" },
          [
            _vm._l(_vm.historyData, function (item) {
              return [
                item.type == "form"
                  ? _c(
                      "el-row",
                      {
                        staticClass: "el-row",
                        staticStyle: {
                          margin: "0",
                          padding: "20px 0",
                          "border-bottom": "1px solid #E5E5E5",
                          "box-sizing": "border-box",
                        },
                        attrs: { gutter: 20, type: "flex" },
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              padding: "0",
                              width: "200px",
                              height: "120px",
                            },
                            attrs: { span: 4, xs: 24 },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "form-img",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetailClick(item)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      item.form && item.form.cover
                                        ? item.form.cover +
                                          "?x-oss-process=image/resize,m_fixed,h_150,w_300"
                                        : _vm.defaultHeadImg +
                                          "?x-oss-process=image/resize,m_fixed,h_150,w_300",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "padding-left": "0",
                              "padding-right": "0",
                              "margin-left": "30px",
                              cursor: "pointer",
                            },
                            attrs: { span: 20, xs: 24 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "form-info",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.onFormMouseEnter(item)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.onFormMouseLeave(item, _vm.index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "clearfix form-title" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "title-span",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDetailClick(item)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.form.name))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-date",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetailClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "fill-date" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "50px",
                                            display: "inline-block",
                                          },
                                        },
                                        [_vm._v("创建时间")]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { margin: "0 10px" } },
                                        [_vm._v(":")]
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(item.form.createTime)),
                                      ]),
                                    ]),
                                  ]
                                ),
                                item.form.fillStartTime ||
                                item.form.fillEndTime ||
                                (item.form.fillStartTime &&
                                  item.form.fillEndTime)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "clearfix form-date",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDetailClick(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("p", { staticClass: "fill-date" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                width: "50px",
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v("填报时间")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { margin: "0 10px" },
                                            },
                                            [_vm._v(":")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.form.fillStartTime)
                                            ),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { margin: "0 5px" },
                                            },
                                            [_vm._v(" - ")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.form.fillEndTime)
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                !item.form.fillStartTime &&
                                !item.form.fillEndTime
                                  ? _c(
                                      "div",
                                      { staticClass: "clearfix form-date" },
                                      [
                                        _c("p", { staticClass: "fill-date" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                width: "50px",
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v("填报时间")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { margin: "0 10px" },
                                            },
                                            [_vm._v(":")]
                                          ),
                                          _c("span", [_vm._v("——")]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix form-date",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetailClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "fill-date" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "50px",
                                            display: "inline-block",
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticStyle: {
                                                "font-style": "normal",
                                              },
                                            },
                                            [_vm._v("创")]
                                          ),
                                          _c(
                                            "i",
                                            {
                                              staticStyle: {
                                                "font-style": "normal",
                                                margin: "0 6px",
                                              },
                                            },
                                            [_vm._v("建")]
                                          ),
                                          _c(
                                            "i",
                                            {
                                              staticStyle: {
                                                "font-style": "normal",
                                              },
                                            },
                                            [_vm._v("人")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { margin: "0 10px" } },
                                        [_vm._v(":")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.form.founder
                                              ? item.form.founder
                                              : item.form.createName
                                          )
                                        ),
                                      ]),
                                      item.form.agencyId
                                        ? _c("img", {
                                            staticClass: "is-agency-icon",
                                            attrs: {
                                              src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                            },
                                          })
                                        : _c("span", [_vm._v("（个人）")]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix form-date",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetailClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "fill-date fl" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "50px",
                                            display: "inline-block",
                                          },
                                        },
                                        [
                                          _vm._v("类"),
                                          _c(
                                            "i",
                                            { staticStyle: { opacity: "0" } },
                                            [_vm._v("类型")]
                                          ),
                                          _vm._v("型"),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { margin: "0 10px" } },
                                        [_vm._v(":")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.type == "project"
                                              ? "应用"
                                              : "表单"
                                          )
                                        ),
                                      ]),
                                      _c("img", {
                                        staticClass: "is-form-icon",
                                        attrs: {
                                          src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-icon.png",
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                item.type == "project"
                  ? _c(
                      "el-row",
                      {
                        staticClass: "el-row",
                        staticStyle: {
                          margin: "0",
                          padding: "20px 0",
                          "border-bottom": "1px solid #E5E5E5",
                          "box-sizing": "border-box",
                          "align-items": "center",
                        },
                        attrs: { gutter: 20, type: "flex" },
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              padding: "0",
                              width: "200px",
                              height: "120px",
                            },
                            attrs: { span: 4, xs: 24 },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "form-img",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetailClick(item)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      item.project && item.project.cover
                                        ? item.project.cover +
                                          "?x-oss-process=image/resize,m_fixed,h_150,w_300"
                                        : _vm.defaultHeadImg +
                                          "?x-oss-process=image/resize,m_fixed,h_150,w_300",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "padding-left": "0",
                              "padding-right": "0",
                              "margin-left": "30px",
                              cursor: "pointer",
                            },
                            attrs: { span: 20, xs: 24 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "form-info",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.onFormMouseEnter(item)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.onFormMouseLeave(item, _vm.index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-date",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetailClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "fill-date" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "50px",
                                            display: "inline-block",
                                          },
                                        },
                                        [_vm._v("创建时间")]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { margin: "0 10px" } },
                                        [_vm._v(":")]
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(item.project.createTime)),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix form-date",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetailClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "fill-date" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "50px",
                                            display: "inline-block",
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticStyle: {
                                                "font-style": "normal",
                                              },
                                            },
                                            [_vm._v("创")]
                                          ),
                                          _c(
                                            "i",
                                            {
                                              staticStyle: {
                                                "font-style": "normal",
                                                margin: "0 6px",
                                              },
                                            },
                                            [_vm._v("建")]
                                          ),
                                          _c(
                                            "i",
                                            {
                                              staticStyle: {
                                                "font-style": "normal",
                                              },
                                            },
                                            [_vm._v("人")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { margin: "0 10px" } },
                                        [_vm._v(":")]
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(item.project.createName)),
                                      ]),
                                      item.project.agencyId
                                        ? _c("img", {
                                            staticClass: "is-agency-icon",
                                            attrs: {
                                              src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                            },
                                          })
                                        : _c("span", [_vm._v("（个人）")]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix form-date",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetailClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "fill-date fl" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "50px",
                                            display: "inline-block",
                                          },
                                        },
                                        [
                                          _vm._v("类"),
                                          _c(
                                            "i",
                                            { staticStyle: { opacity: "0" } },
                                            [_vm._v("类型")]
                                          ),
                                          _vm._v("型"),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { margin: "0 10px" } },
                                        [_vm._v(":")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.type == "project"
                                              ? "应用"
                                              : "表单"
                                          )
                                        ),
                                      ]),
                                      _c("img", {
                                        staticClass: "is-project-icon",
                                        attrs: {
                                          src:
                                            item.type == "project"
                                              ? "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/project-icon.png"
                                              : "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-icon.png",
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      : _c("div", { staticClass: "list-no-data" }, [
          _c("img", {
            staticClass: "no-data-img",
            attrs: {
              src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collect_no.jpg",
            },
          }),
          _c("span", { staticClass: "no-data-text" }, [
            _vm._v("暂无浏览数据~"),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }