<template>
  <div>
    <div class="headPortrait-group--data" @click="editCropper()">
      <div class="headPortrait-group--hover" />
      <img :src="options.img + '?x-oss-process=image/resize,m_fixed,h_150,w_300'" title="点击上传头像" class="img-circle img-lg">
    </div>
    <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body @opened="modalOpened">
      <el-row>
        <el-col :xs="24" :md="12" :style="{height: '350px'}">
          <vue-cropper
            v-if="visible"
            ref="cropper"
            :img="options.img"
            :info="true"
            :auto-crop="options.autoCrop"
            :auto-crop-width="options.autoCropWidth"
            :auto-crop-height="options.autoCropHeight"
            :fixed-box="options.fixedBox"
            @realTime="realTime"
          />
        </el-col>
        <el-col :xs="24" :md="12" :style="{height: '350px'}">
          <div class="avatar-upload-preview">
            <img :src="previews.url" :style="previews.img">
          </div>
        </el-col>
      </el-row>
      <br>
      <el-row>
        <el-col :lg="2" :md="2">
          <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload">
            <el-button size="small">
              上传
              <i class="el-icon-upload el-icon--right" />
            </el-button>
          </el-upload>
        </el-col>
        <el-col :lg="{span: 1, offset: 2}" :md="2">
          <el-button icon="el-icon-plus" size="small" @click="changeScale(1)" />
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-minus" size="small" @click="changeScale(-1)" />
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()" />
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()" />
        </el-col>
        <el-col :lg="{span: 2, offset: 6}" :md="2">
          <el-button type="primary" size="small" @click="uploadImg()">提 交</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { globalBus } from '@/utils/globalBus'
import store from '@/store'
import { VueCropper } from 'vue-cropper'
import { getSessionStorageObj, setSessionStorageObj } from '@/utils/db'
import { getOssSts } from '@/api/tool/oss'
import ossClient from '@/utils/oss'
import { getUserInfo, updateUserInfo } from '@/api/system/user'

export default {
  components: { VueCropper },
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 是否显示cropper
      visible: false,
      // 弹出层标题
      title: '修改头像',
      options: {
        img: '',
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true // 固定截图框大小 不允许改变
      },
      previews: {},
      uploadConf: {},
      progress: 0, // 进度条
      file: {}
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      getUserInfo().then(response => {
        if (response.code == 200) {
          this.options.img = this.user.avatarUrl ? this.user.avatarUrl : 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/profile.jpg' // 裁剪图片的地址
        } else {
          this.msgError(response.msg)
        }
      })
    },
    getOss() {
      if (!this.isBlank(getSessionStorageObj('ossInfo'))) {
        const expirationM = getSessionStorageObj('ossInfo').expiration
        const currentM = new Date().getTime()
        if (currentM >= expirationM) {
          this.getOssInfo()
        }
      } else {
        this.getOssInfo()
      }

      const self = this
      setTimeout(function() {
        self.initOssConf()
      }, 1000)
    },
    getOssInfo() {
      getOssSts().then((res) => {
        if (res.code == 200) {
          setSessionStorageObj('ossInfo', res.data)
        }
      })
    },
    initOssConf() {
      const stsObj = getSessionStorageObj('ossInfo')
      const sts = {
        Region: 'oss-cn-beijing',
        AccessKeyId: stsObj.accessKeyId,
        AccessKeySecret: stsObj.accessKeySecret,
        Bucket: stsObj.bucketName,
        SecurityToken: stsObj.securityToken,
        Secure: true
      }
      const { Region, AccessKeyId, AccessKeySecret, Bucket, SecurityToken, Secure } = sts
      this.uploadConf.region = Region
      this.uploadConf.accessKeyId = AccessKeyId
      this.uploadConf.accessKeySecret = AccessKeySecret
      this.uploadConf.bucket = Bucket
      this.uploadConf.stsToken = SecurityToken
      this.uploadConf.secure = Secure
    },
    // 编辑头像
    editCropper() {
      this.open = true
    },
    // 打开弹出层结束时的回调
    modalOpened() {
      this.visible = true
    },
    // 覆盖默认的上传行为
    requestUpload() {
    },
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf('image/') == -1) {
        this.msgError('文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。')
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.options.img = reader.result
          this.file = file
        }
      }
    },
    // 上传图片
    uploadImg() {
      this.getOss()
      this.$refs.cropper.getCropBlob(data => {
        const formData = new FormData()
        formData.append('avatarfile', data)
        const sessionStorageUser = getSessionStorageObj('users')
        const fileName = sessionStorageUser.userId + '/' + Date.parse(new Date()) + '_' + this.file.name
        const self = this
        ossClient(this.uploadConf).put(fileName, this.file, { 'ContentType': 'image/jpeg' }, {
          progress: function(p) { // 获取进度条的值
            self.progress = p * 100
          }
        }).then(({ res, url, name }) => {
          if (res && res.status == 200) {
            const storageUserInfo = getSessionStorageObj('users')
            const updateUserInfoObj = {
              avatarUrl: url,
              nickName: storageUserInfo.nickName,
              sex: storageUserInfo.sex ? storageUserInfo.sex : 1
            }
            updateUserInfo(updateUserInfoObj).then(response => {
              if (response.code == 200) {
                this.open = false
                this.options.img = url
                store.commit('SET_AVATAR', this.options.img)
                this.msgSuccess('修改成功')
                this.visible = false
                globalBus.$emit('refreshUser')
              }
            })
          }
          this.$refs.cropper.clearCrop()
        })
      })
    },
    // 实时预览
    realTime(data) {
      this.previews = data
    }
  }
}
</script>
<style scoped>
.headPortrait-group--data {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}
.headPortrait-group--hover {
  position: absolute;
  width: 120px;
  height: 120px;
  line-height: 120px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 60px;
  color: #fff;
  cursor: pointer;
}
.headPortrait-group--hover:hover {
  background: rgba(0, 0, 0, 0.35);
}
.headPortrait-group--hover:hover::after {
  content: "更换头像";
}
</style>
