<template>
  <div class="clearfix app-pwd-container" v-cloak>

    <div class="team-container">
      <div class="team-info-detail">
        <div class="fx-row-info-panel">
          <div class="label">手机</div>
          <div class="content">
            <span class="content-row" v-if="userInfo.mobile">{{userInfo.mobile}}</span>
            <el-button type="text" class="x-button" @click="onUserPhoneClick">{{userInfo.mobile ? '更换': '绑定'}}</el-button>
          </div>
        </div>
        <div class="fx-row-info-panel">
          <div class="label">邮箱</div>
          <div class="content">
            <span class="content-row" v-if="userInfo.email">{{userInfo.email}}</span>
            <el-button type="text" class="x-button" @click="onUserEmailClick" :style="{padding: userInfo.email ? '0 20px' : '0 0'}">{{userInfo.email ? '更换': '绑定'}}</el-button>
          </div>
        </div>
        <div class="fx-row-info-panel">
          <div class="label">密码</div>
          <div class="content">
            <el-button type="text" class="x-button" @click="onEditUserPwd" style="padding: 0 20px 0 0">修改</el-button>
            <el-button type="text" class="x-button" @click="onResetUserPwd" style="padding: 0 0; margin-left: 0" v-if="userInfo.mobile">重置</el-button>
          </div>
        </div>
      </div>
    </div>

    <!--绑定手机、邮箱-->
    <el-dialog title="" :visible.sync="bindOpen" width="450px" append-to-body @open="onOpenBind" @close="onCloseBind">
      <el-form ref="bindForm" type="flex" :model="bindForm" :rules="bindFormRules" label-position="left" label-width="70px">
        <el-form-item prop="username" ref="username" :label="bindForm.type == 'phone' ? '手机号':'邮箱'">
          <el-input v-model="bindForm.username" type="text" auto-complete="off" :placeholder="bindForm.type == 'phone' ? '请输入绑定手机号' : '请输入绑定邮箱'"></el-input>
        </el-form-item>
        <el-form-item prop="code" ref="code" label="验证码">
          <el-input v-model="bindForm.code" auto-complete="off" placeholder="请输入验证码" style="width: calc(100% - 120px)"></el-input>
          <div class="fr send-code">
            <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-show="isGetCode" @click.native.prevent="onCodeClick">获取验证码</el-button>
            <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-show="!isGetCode" :disabled="disabled=!isGetCode">{{bindCount}}s后重试</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="width: 50%; margin-top: -30px; margin-left: auto;margin-right: auto;">
        <el-button type="primary" style="width:100%;" @click.native.prevent="handleBind">确认绑定</el-button>
      </div>
    </el-dialog>
    <!--绑定手机、邮箱-->

    <!--更换绑定手机、邮箱-->
    <el-dialog title="" :visible.sync="updateBindOpen" width="450px" append-to-body @open="onUpdateBindOpen" @close="onUpdateBindClose">
      <template v-if="updateBindStep == 1">
        <div class="VerificationDialogModalHeader">
          <h1 class="VerificationDialogModalHeader-title">身份验证</h1>
          <h2 class="VerificationDialogModalHeader-subTitle">为了保护你的帐号安全，请验证身份，验证成功后进行下一步操作</h2>
          <div class="VerificationDialogModal-oneSelection" v-if="updateBindForm.type == 'phone'">使用手机{{userInfo.mobile}}验证</div>
          <div class="VerificationDialogModal-oneSelection" v-if="updateBindForm.type == 'email'">使用邮箱{{userInfo.email}}验证</div>
          <el-form ref="updateBindForm" type="flex" :model="updateBindForm" :rules="updateBindFormRules" label-position="left" label-width="70px">
            <el-form-item prop="changeBindingCode" ref="changeBindingCode" label="验证码">
              <el-input v-model="updateBindForm.changeBindingCode" auto-complete="off" placeholder="请输入验证码" style="width: calc(100% - 120px)"></el-input>
              <div class="fr send-code">
                <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-if="isGetUpdateBindCode" @click.native.prevent="onOriginalCodeClick">获取验证码</el-button>
                <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-else :disabled="isGetUpdateBindCode ? false : true">{{updateBindCount}}s后重试</el-button>
              </div>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer" style="width: 50%; margin-left: auto;margin-right: auto;">
            <el-button type="primary" style="width:100%;" @click.native.prevent="handleCheckout">验证</el-button>
          </div>
        </div>
      </template>
      <template v-if="updateBindStep == 2">
        <el-form ref="updateBindForm" type="flex" :model="updateBindForm" :rules="updateBindFormRules" label-position="left" label-width="70px">
          <el-form-item prop="username" ref="username" :label="updateBindForm.type == 'phone' ? '手机号':'邮箱'">
            <el-input v-model="updateBindForm.username" type="text" auto-complete="off" :placeholder="updateBindForm.type == 'phone' ? '请输入绑定手机号' : '请输入绑定邮箱'"></el-input>
          </el-form-item>
          <el-form-item prop="code" ref="code" label="验证码">
            <el-input v-model="updateBindForm.code" auto-complete="off" placeholder="请输入验证码" style="width: calc(100% - 120px)"></el-input>
            <div class="fr send-code">
              <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-if="isGetNewCode" @click.native.prevent="onNewCodeClick">获取验证码</el-button>
              <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-else :disabled="isGetNewCode ? false : true">{{bindNewCount}}s后重试</el-button>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="width: 50%; margin-left: auto;margin-right: auto;">
          <el-button type="primary" style="width:100%;" @click.native.prevent="onUpdateConfirmBind">确认绑定</el-button>
        </div>
      </template>
    </el-dialog>
    <!--更换绑定手机、邮箱-->

    <!--修改密码-->
    <el-dialog title="修改密码" :visible.sync="editPwdOpen" width="500px" append-to-body @open="onOpenEdit" @close="onCloseEdit">
      <el-form ref="editPwdForm" type='flex' :model="editPwdForm" :rules="editPwdFormRules" label-width="80px">
        <el-form-item label="旧密码" prop="oldPassword" style="margin-bottom: 30px">
          <el-input v-model="editPwdForm.oldPassword" type="password" autocomplete="off" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password" style="margin-bottom: 30px">
          <el-input v-model="editPwdForm.password" type="password" autocomplete="off" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword" style="margin-bottom: 30px">
          <el-input v-model="editPwdForm.confirmPassword" type="password" autocomplete="off" placeholder="请确认密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px">
        <el-button type="primary" @click="onConfirmEditPwd">确 定</el-button>
        <el-button @click="onCancelEditPwd">取 消</el-button>
      </div>
    </el-dialog>
    <!--修改密码-->

    <!--重置密码-->
    <el-dialog title="重置密码" :visible.sync="resetPwdOpen" width="500px" append-to-body @open="onOpenReset" @close="onCloseReset">
      <el-form ref="forgetPwdForm" :model="forgetPwdForm" :rules="forgetPwdFormRules">
        <div class="VerificationDialogModal-oneSelection">使用手机{{userInfo.mobile}}重置密码</div>
        <el-form-item prop="code" style="margin-bottom: 30px">
          <el-input v-model="forgetPwdForm.code" auto-complete="off" placeholder="请输入手机验证码" style="width: calc(100% - 120px)"></el-input>
          <div class="fr send-code">
            <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-show="isGetForgetPwdCode" @click.native.prevent="getForgetPwdCode">获取验证码</el-button>
            <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-show="!isGetForgetPwdCode" :disabled="disabled=!isGetForgetPwdCode">{{forgetPwdCount}}s后重试</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="password" style="margin-bottom: 30px">
          <el-input v-model="forgetPwdForm.password" type="password" autocomplete="off" placeholder="请重新输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" style="margin-bottom: 30px">
          <el-input v-model="forgetPwdForm.confirmPassword" type="password" autocomplete="off" placeholder="请再次输入密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px">
        <el-button type="primary" @click="onConfirmResetPwd">确 定</el-button>
        <el-button @click="onCancelResetPwd">取 消</el-button>
      </div>
    </el-dialog>
    <!--重置密码-->

  </div>
</template>

<script>
const TIME_COUNT = 60

import {getCodeImg, getPwdMobileCaptcha, resetPwd, sendBindingCode, updatePwd, sendChangeBindingCode, securityBinding, checkChangeBindingCode} from "@/api/login";

export default {
  name: "pwdReset",
  components: {

  },
  data() {
    return {
      userInfo: {},
      // 绑定手机、邮箱
      bindOpen: false,
      bindForm: {
        code: '',
        type: 'phone', // phone  or  email
        username: ''
      },
      bindFormRules: {
        username	: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let errorMsg = this.bindForm.type == 'phone' ? '手机号不能为空' : '邮箱不能为空'
              if (value == '' || !value) {
                callback(new Error(errorMsg))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        code: [
          { required: true, trigger: ["blur"], message: "验证码不能为空" }
        ]
      },
      isGetCode: true,
      bindCount: 0,
      bindTimer: null,
      // 更换绑定手机、邮箱
      updateBindOpen: false,
      updateBindForm: {
        changeBindingCode: '',
        type: 'phone',
        username: '',
        code: ''
      },
      updateBindFormRules: {
        changeBindingCode: [
          { required: true, trigger: ["blur"], message: "验证码不能为空" }
        ],
        username	: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let errorMsg = this.updateBindForm.type == 'phone' ? '手机号不能为空' : '邮箱不能为空'
              if (value == '' || !value) {
                callback(new Error(errorMsg))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        code: [
          { required: true, trigger: ["blur"], message: "验证码不能为空" }
        ]
      },
      isGetUpdateBindCode: true,
      UpdateTime: 60,
      updateBindCount: 0,
      updateBindTimer: null,
      updateBindStep: 1,
      isGetNewCode: true,
      bindNewTime: 60,
      bindNewCount: 0,
      bindNewTimer: null,
      // 修改密码
      editPwdOpen: false,
      editPwdForm: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      editPwdFormRules : {
        oldPassword: [
          { required: true, trigger: "blur", message: "原密码不能为空" }
        ],
        password: [
          { required: true, trigger: ["blur"], message: "新密码不能为空" }
        ],
        confirmPassword: [
          { required: true, trigger: ["blur"], message: "请确认密码" }
        ],
      },
      // 重置密码
      resetPwdOpen: false,
      pwdImgCaptcha: '',
      forgetPwdForm: {
        imgCaptcha: '',
        uuid: '',
        mobile: '',
        code: '',
        password: '',
        confirmPassword: ''
      },
      forgetPwdFormRules : {
        // mobile: [
        //   { required: true, trigger: "blur", message: "手机号不能为空" }
        // ],
        code: [
          { required: true, trigger: ["blur"], message: "验证码不能为空" }
        ],
        password: [
          { required: true, trigger: ["blur"], message: "密码不能为空" }
        ],
        confirmPassword: [
          { required: true, trigger: ["blur"], message: "请再次输入密码" }
        ]
      },
      isGetForgetPwdCode: true,
      forgetPwdTime: 60,
      forgetPwdCount: 0,
      forgetPwdTimer: null,
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      let self = this
      this.$store.dispatch("GetUserInfo").then((res) => {
        self.userInfo = res.data
      })
    },
    getPwdImgCaptcha() {
      getCodeImg().then(res => {
        this.pwdImgCaptcha = "data:image/gif;base64," + res.img
        this.forgetPwdForm.uuid = res.uuid
      })
        .catch((error) => {
          console.log(error)
        })
    },
    // 重置密码获取短信验证码
    getForgetPwdCode() {
      let _this = this
      let param = {
        mobile: _this.userInfo.mobile
      }
      getPwdMobileCaptcha(param).then(res => {
        if(res.code == 200) {
          _this.forgetPwdCount = _this.forgetPwdTime;
          _this.isGetForgetPwdCode = false;
          _this.forgetPwdTimer = setInterval(function(){
            if (_this.forgetPwdCount > 0 && _this.forgetPwdCount <= _this.forgetPwdTime) {
              _this.forgetPwdCount--;
            } else {
              _this.isGetForgetPwdCode = true;
              clearInterval(_this.forgetPwdTimer);
              _this.forgetPwdTimer = null;
            }
          }, 1000)
          _this.msgSuccess("验证码发送成功");
        }
      })
    },
    // 确认重置密码
    onConfirmResetPwd() {
      let _this = this
      _this.$refs.forgetPwdForm.validate(valid => {
        if (valid) {
          let param = {
            code: _this.forgetPwdForm.code,
            mobile: _this.userInfo.mobile,
            password: _this.forgetPwdForm.password,
            confirmPassword: _this.forgetPwdForm.confirmPassword
          }
          resetPwd(param).then(res => {
            _this.$message({
              message: '重置密码成功',
              type: "success"
            })
            _this.resetPwdOpen = false
            _this.forgetPwdForm = {
              imgCaptcha: '',
              uuid: '',
              mobile: '',
              code: '',
              password: '',
              confirmPassword: ''
            }
            _this.$refs.forgetPwdForm.resetFields()
            _this.$refs['forgetPwdForm'].clearValidate()
            _this.isGetForgetPwdCode = true
            _this.forgetPwdTime = 60
            _this.forgetPwdCount = 0
            clearTimeout(_this.forgetPwdTimer)
            _this.forgetPwdTimer = null
          })
        }
      })
    },
    // 重置密码
    onResetUserPwd() {
      this.resetPwdOpen = true
      this.forgetPwdForm = {
        imgCaptcha: '',
        uuid: '',
        mobile: '',
        code: '',
        password: '',
        confirmPassword: ''
      }
      if (this.$refs['forgetPwdForm'] !== undefined) {
        this.$refs.forgetPwdForm.clearValidate()
        this.$refs['forgetPwdForm'].resetFields()
      }
      this.isGetForgetPwdCode = true
      this.forgetPwdTime = 60
      this.forgetPwdCount = 0
      clearTimeout(this.forgetPwdTimer)
      this.forgetPwdTimer = null
    },
    onOpenReset() {
      this.$nextTick(() => {
        this.$refs.forgetPwdForm.clearValidate()
        this.$refs['forgetPwdForm'].resetFields()
      })
    },
    // 取消重置密码
    onCancelResetPwd() {
      this.resetPwdOpen = false
      this.forgetPwdForm = {
        imgCaptcha: '',
        uuid: '',
        mobile: '',
        code: '',
        password: '',
        confirmPassword: ''
      }
      this.$refs.forgetPwdForm.clearValidate()
      this.$refs['forgetPwdForm'].resetFields()
      this.isGetForgetPwdCode = true
      this.forgetPwdTime = 60
      this.forgetPwdCount = 0
      clearTimeout(this.forgetPwdTimer)
      this.forgetPwdTimer = null
    },
    // 关闭重置密码
    onCloseReset() {
      this.resetPwdOpen = false
      this.forgetPwdForm = {
        imgCaptcha: '',
        uuid: '',
        mobile: '',
        code: '',
        password: '',
        confirmPassword: ''
      }
      this.$refs.forgetPwdForm.clearValidate()
      this.$refs['forgetPwdForm'].resetFields()
      this.isGetForgetPwdCode = true
      this.forgetPwdTime = 60
      this.forgetPwdCount = 0
      clearTimeout(this.forgetPwdTimer)
      this.forgetPwdTimer = null
    },
    // 绑定、更换邮箱
    onUserEmailClick() {
      if(this.isBlank(this.userInfo.email)) {
        // 绑定邮箱
        this.bindOpen = true
        this.bindForm.type = 'email'
      } else {
        // 更换邮箱
        this.updateBindOpen = true
        this.updateBindForm.type = 'email'
      }
    },
    // 绑定、更换手机号
    onUserPhoneClick(){
      if(this.isBlank(this.userInfo.mobile)) {
        // 绑定手机号
        this.bindOpen = true
        this.bindForm.type = 'phone'
      } else {
        // 更换手机号
        this.updateBindOpen = true
        this.updateBindForm.type = 'phone'
      }
    },
    onOpenBind() {
      this.$nextTick(() => {
        this.$refs.bindForm.clearValidate()
        this.$refs['bindForm'].resetFields()
      })
    },
    // 点击获取验证码
    onCodeClick() {
      let _this = this
      if(_this.isBlank(_this.bindForm.username)) {
        let message = ''
        if (_this.bindForm.type == 'phone') {
          message = '请先输入手机号'
        } else {
          message = '请先输入邮箱'
        }
        _this.msgError(message);
        return;
      }
      let param = {
        type: _this.bindForm.type,
        username: _this.bindForm.username
      }
      sendBindingCode(param).then(res => {
        if (res.code === 200) {
          _this.bindCount = TIME_COUNT;
          _this.isGetCode = false;
          _this.bindTimer = setInterval(function(){
            if (_this.bindCount > 0 && _this.bindCount <= TIME_COUNT) {
              _this.bindCount--;
            } else {
              _this.isGetCode = true;
              clearInterval(_this.bindTimer);  // 清除定时器
              _this.bindTimer = null;
            }
          }, 1000)
          _this.msgSuccess("验证码发送成功");
        } else {
          _this.msgError(response.msg);
          _this.isGetCode = true;
          clearInterval(_this.bindTimer);
          _this.bindTimer = null;
        }
      });
    },
    // 确认绑定
    handleBind() {
      let _this = this
      _this.$refs.bindForm.validate(valid => {
        if (valid) {
          securityBinding(_this.bindForm).then(res => {
            let successMsg = _this.bindForm.type == 'phone' ? '绑定手机号成功' : '绑定邮箱成功'
            this.$message({
              message: successMsg,
              type: "success"
            })
            _this.bindOpen = false
            _this.bindForm = {
              code: '',
              type: 'phone',
              username: ''
            }
            _this.$refs.bindForm.resetFields()
            _this.$refs['bindForm'].clearValidate()
            clearTimeout(_this.bindTimer)
            _this.bindTimer = null
            _this.getUserInfo()
          })
        }
      })
    },
    onCloseBind() {
      this.bindOpen = false
      this.bindForm = {
        code: '',
        type: 'phone',
        username: ''
      }
      this.$refs.bindForm.resetFields()
      this.$refs['bindForm'].clearValidate()
      clearTimeout(this.bindTimer)
      this.bindTimer = null
    },
    // 修改密码
    onEditUserPwd() {
      this.editPwdOpen = true
    },
    onOpenEdit() {
      this.$nextTick(() => {
        this.$refs['editPwdForm'].clearValidate()
        this.$refs.editPwdForm.resetFields()
      })
    },
    onCloseEdit() {
      this.editPwdOpen = false
      this.editPwdForm = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      }
    },
    onConfirmEditPwd() {
      let _this = this
      _this.$refs.editPwdForm.validate(valid => {
        if (valid) {
          updatePwd(_this.editPwdForm).then(res => {
            _this.$message({
              message: '修改密码成功',
              type: "success"
            })
            _this.editPwdOpen = false
            _this.editPwdForm = {
              oldPassword: '',
              password: '',
              confirmPassword: ''
            }
            _this.$refs.editPwdForm.resetFields()
            _this.$refs['editPwdForm'].clearValidate()
          })
        }
      })
    },
    onCancelEditPwd() {
      this.editPwdOpen = false
    },
    // 更换手机号、邮箱
    onUpdateBindOpen() {
      this.$nextTick(() => {
        this.$refs.updateBindForm.clearValidate()
        this.$refs['updateBindForm'].resetFields()
      })
    },
    // 更换手机号、邮箱 验证身份 点击获取已绑定手机、邮箱验证码
    onOriginalCodeClick() {
      let _this = this
      let param = {
        type: _this.updateBindForm.type
      }
      console.log(param)
      // return
      sendChangeBindingCode(param).then(res => {
        if (res.code === 200) {
          _this.updateBindCount = _this.UpdateTime;
          _this.isGetUpdateBindCode = false;
          _this.updateBindTimer = setInterval(function(){
            if (_this.updateBindCount > 0 && _this.updateBindCount <= _this.UpdateTime) {
              _this.updateBindCount--;
              // console.log(_this.updateBindCount)
            } else {
              _this.isGetUpdateBindCode = true;
              clearInterval(_this.updateBindTimer);  // 清除定时器
              _this.updateBindTimer = null;
            }
          }, 1000)
          _this.msgSuccess("验证码发送成功");
        } else {
          _this.msgError(response.msg);
          _this.isGetUpdateBindCode = true;
          clearInterval(_this.updateBindTimer);
          _this.updateBindTimer = null;
        }
      });
    },
    // 更换手机号、邮箱 验证身份
    handleCheckout() {
      let _this = this
      let param = {
        type: _this.updateBindForm.type,
        code: _this.updateBindForm.changeBindingCode
      }
      _this.$refs.updateBindForm.validate(valid => {
        if (valid) {
          checkChangeBindingCode(param).then(res => {
            if(res.code === 200) {
              _this.updateBindStep = 2
            }
          })
        }
      })
    },
    // 更换手机号、邮箱 确认绑定新手机、邮箱 获取验证码
    onNewCodeClick() {
      console.log('更换手机号、邮箱 确认绑定新手机、邮箱 获取验证码')
      let _this = this
      if(_this.isBlank(_this.updateBindForm.username)) {
        let message = ''
        if (_this.updateBindForm.type == 'phone') {
          message = '请先输入手机号'
        } else {
          message = '请先输入邮箱'
        }
        _this.msgError(message);
        return;
      }
      let param = {
        type: _this.updateBindForm.type,
        username: _this.updateBindForm.username
      }
      sendBindingCode(param).then(res => {
        if (res.code === 200) {
          _this.bindNewCount = _this.bindNewTime;
          _this.isGetNewCode = false;
          _this.bindNewTimer = setInterval(function(){
            if (_this.bindNewCount > 0 && _this.bindNewCount <= _this.bindNewTime) {
              _this.bindNewCount--;
              console.log(_this.bindNewCount)
            } else {
              _this.isGetNewCode = true;
              clearInterval(_this.bindNewTimer);  // 清除定时器
              _this.bindNewTimer = null;
            }
          }, 1000)
          _this.msgSuccess("验证码发送成功");
        } else {
          _this.msgError(response.msg);
          _this.isGetNewCode = true;
          clearInterval(_this.bindNewTimer);
          _this.bindNewTimer = null;
        }
      });
    },
    // 更换手机号、邮箱 确认绑定新手机、邮箱
    onUpdateConfirmBind() {
      let _this = this
      _this.$refs.updateBindForm.validate(valid => {
        let param = {
          changeBindingCode: _this.updateBindForm.changeBindingCode,
          type: _this.updateBindForm.type,
          code: _this.updateBindForm.code,
          username: _this.updateBindForm.username
        }
        securityBinding(param).then(res => {
          let successMsg = _this.updateBindForm.type == 'phone' ? '更换手机号成功' : '更换邮箱成功'
          _this.$message({
            message: successMsg,
            type: "success"
          })
          _this.updateBindStep = 1
          _this.updateBindForm = {
            changeBindingCode: '',
            type: 'phone',
            username: '',
            code: ''
          }
          _this.$refs.updateBindForm.resetFields()
          _this.$refs['updateBindForm'].clearValidate()
          clearTimeout(_this.bindNewTimer)
          _this.bindNewTimer = null
          _this.updateBindOpen = false
          _this.getUserInfo()
        })
      })
    },
    onUpdateBindClose() {
      this.updateBindOpen = false
      this.updateBindForm = {
        changeBindingCode: '',
        type: 'phone',
        username: '',
        code: ''
      }
      this.$refs.updateBindForm.resetFields()
      this.$refs['updateBindForm'].clearValidate()
      clearTimeout(this.updateBindTimer)
      this.updateBindTimer = null
      clearTimeout(this.bindNewTimer)
      this.bindNewTimer = null
    }
  }
}
</script>
<style lang="scss" scoped>
  [v-cloak] {
    display: none;
  }
  .app-pwd-container{
    width: 100%;
    margin: auto;
    /*新版style*/
    .team-info-detail{
      margin: 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #e9e9e9;
      padding: 10px 0;
      .fx-row-info-panel{
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        min-height: 80px;
        border-bottom: 1px solid #e9e9e9;
        margin: 0 10px;
        .label {
          -webkit-box-flex: 0;
          -ms-flex: none;
          flex: none;
          width: 130px;
          text-align: center;
          background-color: #f9f9f9;
          line-height: 80px;
          color: #333333;
          font-size: 14px;
        }
        .content {
          padding: 0 20px;
          .content-row{
            line-height: 80px;
            color: #333333;
            font-size: 14px;
          }
          .x-button{
            padding: 0 20px;
            cursor: pointer;
            line-height: 80px;
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .captchaImg{
      img {
        height: 36px;
      }
    }
  }
  /*更换手机号、邮箱*/
  .VerificationDialogModalHeader{
    width: 100%;
    text-align: center;
    .VerificationDialogModalHeader-title {
      margin-top: 0;
      font-size: 24px;
      color: #2b3441;
      margin-bottom: 10px;
      padding: 0;
    }
    .VerificationDialogModalHeader-subTitle {
      max-width: 240px;
      margin: 2px auto 24px;
      font-size: 14px;
      color: #8590a6;
      line-height: 22px;
    }
  }
  .VerificationDialogModal-oneSelection{
    font-size: 14px;
    text-align: left;
    color: #2b3441;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .send-code{
    width: 100px;
    height: 32px;
  }
</style>
