<template>
  <div class="app-user-collect" v-cloak>
    <div class="list-content" v-if="historyData.length > 0">
      <template v-for="item in historyData" >
        <el-row :gutter="20" class="el-row" type="flex" style="margin: 0; padding: 20px 0; border-bottom: 1px solid #E5E5E5; box-sizing: border-box;" v-if="item.type == 'form'">
          <el-col :span="4" :xs="24" style="padding: 0; width: 200px; height: 120px;">
              <span class="form-img" @click="onDetailClick(item)">
                <img :src="item.form && item.form.cover ? item.form.cover + '?x-oss-process=image/resize,m_fixed,h_150,w_300' : defaultHeadImg + '?x-oss-process=image/resize,m_fixed,h_150,w_300'">
              </span>
          </el-col>
          <el-col :span="20" :xs="24" style="padding-left: 0; padding-right: 0; margin-left: 30px; cursor: pointer">
            <div class="form-info" @mouseenter="onFormMouseEnter(item)" @mouseleave="onFormMouseLeave(item, index)">
              <div class="clearfix form-title">
                <span class="title-span" @click="onDetailClick(item)">{{item.form.name}}</span>
              </div>
              <div class="form-date" @click="onDetailClick(item)">
                <p class="fill-date">
                  <span style=" width: 50px; display: inline-block;">创建时间</span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{item.form.createTime}}</span>
                </p>
              </div>
              <div class="clearfix form-date" v-if="item.form.fillStartTime || item.form.fillEndTime || (item.form.fillStartTime && item.form.fillEndTime)" @click="onDetailClick(item)">
                <p class="fill-date">
                  <span style=" width: 50px; display: inline-block;">填报时间</span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{item.form.fillStartTime}}</span>
                  <span style="margin: 0 5px"> - </span>
                  <span>{{item.form.fillEndTime}}</span>
                </p>
              </div>
              <div class="clearfix form-date" v-if="!item.form.fillStartTime && !item.form.fillEndTime">
                <p class="fill-date">
                  <span style=" width: 50px; display: inline-block;">填报时间</span>
                  <span style="margin: 0 10px">:</span>
                  <span>——</span>
                </p>
              </div>
              <div class="clearfix form-date" @click="onDetailClick(item)">
                <div class="fill-date">
                  <span style=" width: 50px; display: inline-block;">
                    <i style="font-style: normal">创</i>
                    <i style="font-style: normal;margin: 0 6px;">建</i>
                    <i style="font-style: normal">人</i>
                  </span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{item.form.founder ? item.form.founder : item.form.createName}}</span>
                  <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png" class="is-agency-icon" v-if="item.form.agencyId"/>
                  <span v-else>（个人）</span>
                </div>
              </div>
              <div class="clearfix form-date" @click="onDetailClick(item)">
                <p class="fill-date fl">
                  <span style=" width: 50px; display: inline-block;">类<i style="opacity: 0;">类型</i>型</span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{item.type == 'project' ? '应用' : '表单'}}</span>
                  <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-icon.png" class="is-form-icon">
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="el-row" type="flex" style="margin: 0; padding: 20px 0; border-bottom: 1px solid #E5E5E5; box-sizing: border-box; align-items: center;" v-if="item.type == 'project'">
          <el-col :span="4" :xs="24" style="padding: 0; width: 200px; height: 120px;">
              <span class="form-img" @click="onDetailClick(item)">
                <img :src="item.project && item.project.cover ? item.project.cover + '?x-oss-process=image/resize,m_fixed,h_150,w_300' : defaultHeadImg + '?x-oss-process=image/resize,m_fixed,h_150,w_300'">
              </span>
          </el-col>
          <el-col :span="20" :xs="24" style="padding-left: 0; padding-right: 0; margin-left: 30px; cursor: pointer">
            <div class="form-info" @mouseenter="onFormMouseEnter(item)" @mouseleave="onFormMouseLeave(item, index)">
              <div class="form-date" @click="onDetailClick(item)">
                <p class="fill-date">
                  <span style=" width: 50px; display: inline-block;">创建时间</span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{item.project.createTime}}</span>
                </p>
              </div>
              <div class="clearfix form-date" @click="onDetailClick(item)">
                <div class="fill-date">
                  <span style=" width: 50px; display: inline-block;">
                    <i style="font-style: normal">创</i>
                    <i style="font-style: normal;margin: 0 6px;">建</i>
                    <i style="font-style: normal">人</i>
                  </span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{item.project.createName}}</span>
                  <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png" class="is-agency-icon" v-if="item.project.agencyId"/>
                  <span v-else>（个人）</span>
                </div>
              </div>
              <div class="clearfix form-date" @click="onDetailClick(item)">
                <p class="fill-date fl">
                  <span style=" width: 50px; display: inline-block;">类<i style="opacity: 0;">类型</i>型</span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{item.type == 'project' ? '应用' : '表单'}}</span>
                  <img :src="item.type == 'project' ? 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/project-icon.png' : 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-icon.png'" class="is-project-icon">
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
    </div>
    <div class="list-no-data" v-else>
      <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collect_no.jpg" class="no-data-img">
      <span class="no-data-text">暂无浏览数据~</span>
    </div>
    <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :pageSizes.sync="pageSizes" @pagination="getList"/> -->

  </div>
</template>

<script>
import html2canvas from "html2canvas"
import { userBrowseList } from "@/api/system/user"
import { getSessionStorageObj } from "@/utils/db";

export default {
  data() {
    return {
      defaultHeadImg: 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg',
      queryParams: {
        pageNum: 1,
        pageSize: 5
      },
      pageSizes:[5,10,15,20],
      isShall:'',
      shallImg:'',
      loginUserId: null,
      historyData: [],

      total: 0
    }
  },
  computed: {},
  mounted() {
    this.loginUserId = getSessionStorageObj('users').userId;
    userBrowseList({
      type: "1",
      typeId: "0"
    }).then(res=>{
      console.log('res',res)
      this.total=res.total
      this.historyData=res.rows
    })
  },
  methods: {
    onFormMouseEnter(item) {
      this.$set(item, 'showBtn', true)
    },
    onFormMouseLeave(item) {
      this.$set(item, 'showBtn', false)
    },
    // 取消收藏
    onCollectClick(item) {
      let operation = 'cancel'
      let param = {}
      param.operation = operation
      param.type = item.type
      param.typeId = item.typeId
      favorite(param).then(response => {
        if (response.code == 200) {
          this.msgSuccess('取消收藏')
          this.$emit('refreshData', true)
        }
      })
    },
    getList(data) {
      this.$emit('getList', data)
    },
    // 点击详情
    onDetailClick(item) {
      let path = ''
      if (item.type == 'form') {
        if (item.form.projectId == 0) {
          path = '/form/detail/' + this.encode(item.form.formId)
        } else {
          path = '/form/multipleFormDetail/' + this.encode(item.form.projectId)+'?formId='+this.encode(item.form.formId)
        }
      } else {
        path = '/form/multipleFormDetail/' + this.encode(item.project.projectId)+'?formId='+this.encode(item.project.projectId)
      }
      this.$router.push({
        path: path
      })
    },
    //点击分享弹框
    onShareClick(item) {
      let self = this
      setOssConfig()
      const locationUrl = window.location.origin
      if (item.type=='form') {
        self.shareFillUrl = locationUrl + '/share/' + self.encode(item.form.formId) + '?type=' + self.encode(2)
        self.shareItemInfo = item.form
        if(item.form.shareUrl == '' || item.form.shareUrl == null){
          self.isShall = 1
          setTimeout(function() {
            self.shareQr(2)
          }, 1000)
        }else{
          self.isShall = 2
          self.shallImg = item.form.shareUrl
        }
      } else {
        self.shareFillUrl = locationUrl + '/share/' + self.encode(item.project.projectId) + '?type=' + self.encode(1)
        self.shareItemInfo = item.project
        if(item.project.shareUrl == '' || item.project.shareUrl == null){
          self.isShall = 1
          setTimeout(function() {
            self.shareQr(1)
          }, 1000)
        }else{
          self.isShall = 2
          self.shallImg = item.project.shareUrl
        }
      }
      self.shareOpen = true
    },
    shareQr(type, inner) {
      let self = this
      html2canvas(self.$refs.imageToFile, {
        //allowTaint: false,
        tainttest:true, //检测每张图片都已经加载完成
        useCORS: true, //设为true 是否尝试使用CORS从服务器加载图像
        //proxy:'http://localhost:1024'
      }).then(canvas => {
        let base64DataUrl = canvas.toDataURL("image/png")
        self.base64DataUrl = base64DataUrl
        let file = self.dataURLtoFile(base64DataUrl, 'shareQr')
        self.uploadShareFile(file, type, inner)
      })
    },
    //base64 转文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    //绘制分享图上传至oss
    uploadShareFile(file, type, inner) {
      let sessionStorageUser = getSessionStorageObj('users')
      const fileName = sessionStorageUser.userId + '/' + Date.parse(new Date()) + '_' + file.name
      let self = this
      putFile(fileName, file).then(url =>{
        self.setFormShareUrl(url, type, inner)
      })
    },
    setFormShareUrl(url, type, inner) {
      let id = !this.isBlank(type) && type == '1' ? this.shareItemInfo.projectId : this.shareItemInfo.formId
      return
      if(type == 1) { // 应用
        this.httpProjectShareUrl(id, url)
      } else { // 表单
        this.httpFormShareUrl(id, url)
      }
    },
    httpProjectShareUrl(id, url) {
      let param = {
        projectId: id,
        shareUrl: url
      }
      setProjectShareUrl(param).then((res)=>{})
    },
    httpFormShareUrl(id, url) {
      let param = {
        formId: id,
        shareUrl: url
      }
      setFormShareUrl(param).then((res)=>{})
    },
    //复制填报地址
    async onCopyFillClick() {
      let oInput = document.createElement('input') //创建input节点
      oInput.value = this.shareFillUrl //给input的value赋值
      document.body.appendChild(oInput) //向页面插入input节点
      oInput.select() // 选中input
      try {
        await document.execCommand('Copy') // 执行浏览器复制命令
        this.$message({
          type: 'success',
          message: '复制成功',
        })
      } catch {
        this.$message({
          type: 'success',
          message: '复制失败',
        })
      }
      document.body.removeChild(oInput)
    },
    //直接打开填报地址页面
    onToClick() {
      let tempwindow = window.open('_blank');
      tempwindow.location.href = this.shareFillUrl
    },
    //保存小程序码
    onSaveClick() {
      if (this.isBlank(this.shareItemInfo.shareQr)) {
        this.$message({
          message: '小程序码缺失，暂无法保存',
          type: 'warning'
        })
        return
      }
      let self = this
      html2canvas(self.$refs.imageToFile, {
        backgroundColor: '#0078F8',
        allowTaint: true,
        useCORS: true //设为true 是否尝试使用CORS从服务器加载图像
      }).then((canvas) => {
        let base64DataUrl = canvas.toDataURL("image/png")
        let alink = document.createElement("a")
        alink.href = base64DataUrl
        alink.download = self.shareItemInfo.name + '二维码.png'
        alink.click()
        document.body.removeChild(alink)
      })
    },
    //监听分享弹框关闭
    handleFillDiaClose() {
      this.shareFillUrl = ''
    },
    // 移动表单
    onMoveFormClick(item) {
      this.moveFormId = item.form.id
      this.formMoveVisible = true
      this.getMoveProject()
    },
    getMoveProject() {
      let _this = this
      projectSelectList().then((response)=>{
        if (response.code == 200) {
          _this.moveFormProjectList = response.data
        }
      })
    },
    onConfirmMoveClick() {
      let _this = this
      this.$refs["formMove"].validate(valid => {
        if (valid) {
          let param = {
            formId: _this.moveFormId,
            projectId: _this.formMoveObj.projectId
          }
          moveForm(param).then(response => {
            if (response.code == 200) {
              _this.msgSuccess("移动成功")
              _this.$emit('refreshData', true)
              _this.formMoveVisible = false
              _this.clearMoveFormData()
            }
          })
        }
      })
    },
    onCancelMoveClick() {
      this.clearMoveFormData()
    },
    onCloseMoveClick() {
      this.clearMoveFormData()
    },
    clearMoveFormData() {
      this.$refs['formMove'].clearValidate()
      this.moveFormId = null
      this.formMoveObj = {
        projectId: null
      }
      this.moveFormProjectList = []
    },
    // 复制表单
    onCopyFormClick(item) {
      let _this = this
      copyForm(item.form.formId).then((response)=>{
        if (response.code == 200) {
          _this.$message({
            type: 'success',
            message: '复制成功'
          })
          _this.$emit('refreshData', true)
        }
      })
    },
    // 编辑修改表单
    onEditFormClick(item) {
      this.$router.push({
        path: '/form/edit/' + this.encode(item.form.formId),
        query: {
          name: this.encode(item.form.name)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
  [v-cloak] {
    display: none;
  }
  .app-user-collect{
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .list-content{
    width: 100%;
    margin-top: -30px;
    .form-img{
      display: inline-block;
      width: 200px;
      height: 120px;
      img{
        width: 100%;
        max-width: 100%;
        height: 120px;
        cursor: pointer;
        border-radius: 6px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
      }
    }
    .form-info {
      .form-title {
        width: 100%;
        margin: 0 0 0px;
        display: inline-block;
        cursor: pointer;
        line-height: 28px;
        .title-span {
          font-size: 16px;
          line-height: 28px;
          color: #394349;
          width: 346px;
          max-width: 346px;
          font-weight: 600;
          display: block;
          overflow: hidden;
          float: left;
          text-overflow: ellipsis;
          white-space: nowrap
        }
        .form-tag{
          margin-left: 60px;
        }
        .form-collect{
          display: inline-block;
          cursor: pointer;
          font-size: 12px;
          color: #909090;
          line-height: 20px;
          .collect-span{
            background: url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collect.png") no-repeat left center;
            background-size: 15px;
            padding-left: 20px;
            &.active{
              background: url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collected.png") no-repeat left center;
              background-size: 15px;
              padding-left: 20px;
            }
          }
        }
        .form-share{
          display: inline-block;
          cursor: pointer;
          float: right;
          font-size: 12px;
          color: #909090;
          line-height: 20px;
          margin-left: 20px;
          .share-span{
            background: url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/share.png") no-repeat left center;
            background-size: 15px;
            padding-left: 20px;
          }
        }
      }
      .form-date{
        width: 100%;
        font-size: 12px;
        height: 20px;
        line-height: 22px;
        margin: 0;
        color: #909090;
        .fill-date {
          margin: 0;
          padding: 0;
          .is-agency-icon{
            display: inline-block;
            width: 32px;
            height: 12px;
            margin-left: 5px;
            vertical-align: -1px;
          }
          .is-project-icon{
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-left: 5px;
            vertical-align: -2px;
          }
          .is-form-icon{
            display: inline-block;
            width: 12px;
            height: 10px;
            margin-left: 5px;
            vertical-align: -1px;
          }
        }
      }
      .form-create{
        font-size: 12px;
        line-height: 12px;
        .form-settings {
          font-size: 12px;
          text-align: right;
          span{
            cursor: pointer;
            padding: 0 10px;
            border-right: 1px solid #707070;
            color: #409EFF;
            img {
              display: inline-block;
              width: 12px;
              height: 12px;
              margin-right: 5px;
              vertical-align: middle;
            }
            &:last-child{
              border-right: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  .list-no-data{
    width: 100%;
    margin: 100px auto;
    text-align: center;
    .no-data-img{
      display: block;
      width: 124px;
      height: auto;
      margin: auto;
    }
    .no-data-text {
      display: block;
      font-size: 16px;
      margin: 20px auto;
    }
  }
  .share-container{
    margin-top: -30px;
    width: 100%;
    p {
      padding: 0;
      margin: 10px auto;
    }
    .shareFillImg{
      display: block;
      width: 200px;
      height: 200px;
      margin-top: 20px;
    }
    .share-lite-contain{
      width: 375px;
      height: 553px;
      background: #0078F8 url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/shareBcg.png") no-repeat 220px 25px;
      background-size: 121px 78px;
      box-shadow: 0px 5px 20px 0px rgba(57, 67, 73, 0.2);
      .top-logo{
        width: 100%;
        padding: 25px 0 25px 25px;
        box-sizing: border-box;
        img {
          display: inline-block;
          vertical-align: middle;
          width: 144px;
          height: 40px;
        }
      }
      .share-card{
        width: calc(100% - 50px);
        height: 438px;
        margin: 0 25px 25px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 6px;
        padding: 20px;
        position: relative;
        .share-form-img{
          display: inline-block;
          vertical-align: middle;
          width: 100%;
          height: 190px;
          margin-bottom: 20px;
        }
        .share-form-name{
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          color: #394349;
          line-height: 22px;
          margin-bottom: 10px;
        }
        .share-form-fill{
          font-size: 13px;
          font-weight: 400;
          color: #394349;
          line-height: 18px;
        }
        .share-form-bottom{
          width: 285px;
          height: 100px;
          padding: 10px 15px;
          box-sizing: border-box;
          background: #F0F2F5;
          position: absolute;
          bottom: 20px;
          .share-form-qr{
            display: inline-block;
            width: 80px;
            height: 80px;
            vertical-align: middle;
          }
          .share-form-qr-text{
            font-size: 11px;
            font-weight: 400;
            color: #394349;
            line-height: 16px;
            text-align: right;
            span{
              display: block;
            }
          }
        }
      }
    }
  }
</style>
