<template>
  <div class="app-user-agency">
    <el-button type="primary" size="mini" @click="onBindClick" class="fr" style="margin-bottom: 30px">绑定机构</el-button>
    <el-table :data="deptData" style="width: 100%" >
      <el-table-column prop="agencyName" label="机构名称" min-width="20%" align="center"></el-table-column>
      <el-table-column prop="name" label="姓名" min-width="25%" align="center"></el-table-column>
      <el-table-column prop="number" label="编号" min-width="20%" align="center">
        <div slot-scope="scope">
          <span>{{scope.row.number ? scope.row.number : '--'}}</span>
        </div>
      </el-table-column>
      <el-table-column prop="agencyRoleName" label="所在部门" min-width="20%" align="center" show-overflow-tooltip>
        <div slot-scope="scope" v-if="scope.row.agencyUserDeptNames && scope.row.agencyUserDeptNames.length > 0">
          <div v-for="(deptItem, deptIndex) in scope.row.agencyUserDeptNames" :key="deptIndex">
            <span>{{deptItem}}</span>
            <span v-if="deptIndex < scope.row.agencyUserDeptNames.length - 1">、</span>
          </div>
        </div>
        <div v-else>
          <div>--</div>
        </div>
      </el-table-column>
      <el-table-column prop="agencyRoleName" label="系统角色" min-width="20%" align="center" show-overflow-tooltip>
        <div slot-scope="scope" v-if="scope.row.agencyUserRoleNames && scope.row.agencyUserRoleNames.length > 0">
          <div v-for="(roleItem,roleIndex) in scope.row.agencyUserRoleNames" :key="roleIndex">
            <span>{{roleItem}}</span>
            <span v-if="roleIndex < scope.row.agencyUserRoleNames.length - 1">、</span>
          </div>
        </div>
        <div v-else>
          <div>--</div>
        </div>
      </el-table-column>
      <!--      <el-table-column label="操作" min-width="25%" align="center">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button size="mini" type="text" icon="el-icon-view">查看</el-button>-->
      <!--          <el-button size="mini" type="text" icon="el-icon-edit">修改</el-button>-->
      <!--          <el-button size="mini" type="text" icon="el-icon-delete">申请解除</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>

    <el-dialog title="绑定机构" :visible.sync="openBind" width="500px" append-to-body :before-close="onHandleBindClose">
      <el-form ref="bindAgency" :model="agencyBind" :rules="agencyBindRules" label-width="90px" label-position="right" @submit.native.prevent><!--W3C标准规定，form表单中如果只有一个输入框，那么在该输入框中按下回车即代表提交表单，这就是触发页面刷新的原因。-->
        <el-form-item prop="value">
          <span slot="label">机构名称</span>
          <el-col :span="24">
            <el-autocomplete class="inline-input" v-model="agencyBind.value" :fetch-suggestions="querySearch" placeholder="请输入机构名称" :trigger-on-focus="false" @select="handleSelect" style="width: 100%"></el-autocomplete>
          </el-col>
        </el-form-item>
        <el-form-item prop="name" v-show="agencyBind.agencyId">
          <span slot="label">姓<em style="opacity: 0;">姓</em><em style="opacity: 0;">名</em>名</span>
          <el-col :span="24">
            <el-input v-model="agencyBind.name" placeholder="请输入姓名"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="学号工号" prop="number" v-show="agencyBind.agencyId">
          <el-col :span="24">
            <el-input v-model="agencyBind.number" placeholder="请输入学号或者工号"></el-input>
          </el-col>
        </el-form-item>
        <div>
          <p>注：管理员未导入学生教师信息前，无法进行绑定操作（校方机构未通知绑定，请勿进行绑定操作）</p>
          <p>添加机构步骤：</p>
          <p>1.学校机构管理员：后台导入学生教师信息。</p>
          <p>2.普通用户：a.搜索机构名称；b.输入正确的学号及姓名，完成绑定。</p>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="onSubmitClick">确 定</el-button>
        <el-button size="mini" @click="OnCancelClick">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { userAgencySearch, bindAgencyUser } from "@/api/system/user";
import { userBrowseList } from "@/api/system/user"

export default {
  props: {
    deptData: {
      type: Array
    }
  },
  data () {
    return {
      openBind: false,
      agencyBind: {
        agencyId: null,
        name: '',
        number: ''
      },
      agencyBindRules: {
        value: [
          { required: true, message: '机构名称不能为空', trigger: ['blur'] },
        ],
        name: [
          { required: true, message: '姓名不能为空', trigger: ['blur', 'change'] },
        ],
        number: [
          { required: true, message: '学号或者工号不能为空', trigger: ['blur', 'change'] },
        ]
      }
    }
  },
  computed: {},
  created () {

  },
  methods: {
    onBindClick () {
      this.openBind = true
    },
    //queryString 为在框中输入的值 callback 回调函数,将处理好的数据推回
    querySearch (queryString, cb) {
      userAgencySearch(queryString).then(response => {
        if (response.code == 200) {
          //在这里为这个数组中每一个对象加一个value字段, 因为autocomplete只识别value字段并在下拉列中显示
          for (let i of response.data) {
            i.value = i.name
          }
          let searchAgencyList = response.data
          cb(searchAgencyList)
        }
      })
    },
    //选中某一列触发的事件
    handleSelect (item) {
      this.agencyBind.agencyId = item.agencyId
    },
    naveToLastestView () {
      // 获取最近浏览记录并读取第1条。如果没有则跳转到首页
      userBrowseList({
        type: "1",
        typeId: "0"
      }).then(res => {
        this.total = res.total
        this.historyData = res.rows

        if (res.rows.length == 0) {
          this.$router.push({ path: '/' })
        } else {
          let item = res.rows[0]
          if (item.type == 'project') { // 应用
            this.$router.push({
              path: '/form/multipleFormDetail/' + this.encode(item.projectId),
            })
          } else if (item.type == 'form') {
            if (item.projectId && item.projectId != "0") { // projectId != 0 代表是应用中的表单，反之是单个表单
              this.$router.push({
                path: '/form/multipleFormDetail/' + this.encode(item.projectId) + '?formId=' + this.encode(item.formId)
              })
            } else {
              this.$router.push({
                path: '/form/detail/' + this.encode(item.formId)
              })
            }
          }
        }

      })
    },
    onSubmitClick () {
      this.$refs["bindAgency"].validate(valid => {
        if (valid) {
          let param = {
            agencyId: this.agencyBind.agencyId,
            name: this.agencyBind.name,
            number: this.agencyBind.number
          }
          bindAgencyUser(param).then(response => {
            if (response.code == 200) {
              // this.msgSuccess("绑定成功")
              this.resetForm()
              this.openBind = false
              this.$emit('refreshAgencyList', true) //绑定成功之后 需要子传父 刷新所属机构列表

              // 点击显示绑定成功，并给出确认是否跳转到最近浏览记录的提示框
              this.$confirm('绑定成功，是否跳转到最近浏览记录？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.naveToLastestView()
              }).catch(() => {
              });
            }
          })
        }
      })
    },
    OnCancelClick () {
      this.resetForm()
      this.openBind = false
    },
    onHandleBindClose () {
      this.resetForm()
      this.openBind = false
    },
    resetForm () {
      this.agencyBind = {
        agencyId: null,
        name: '',
        number: ''
      }
      if (this.$refs['bindAgency']) {
        this.$refs['bindAgency'].resetFields();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.app-user-agency {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}
</style>
