<template>
  <div class="app-user-appeal">
    <el-form ref="appealForm" :model="appealForm" :rules="appealRules" label-width="80px" label-position="right">
      <el-form-item>
        <span slot="label"><em style="color: #ff4949;margin-right: 4px;">*</em>类<em style="opacity: 0;">类</em><em style="opacity: 0;">型</em>型</span>
        <el-col :span="12">
          <el-radio-group v-model="appealForm.type">
            <el-radio v-model="appealForm.type" label="1" @change="onAppealChange" style="margin-bottom:0;">建议</el-radio>
            <el-radio v-model="appealForm.type" label="2" @change="onAppealChange" style="margin-bottom:0;">投诉</el-radio>
          </el-radio-group>
        </el-col>
      </el-form-item>
      <el-form-item label="联系方式" prop="contact">
        <el-col :span="12">
          <el-input v-model="appealForm.contact" placeholder="请输入联系方式"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item prop="content">
        <span slot="label" v-if="appealForm.type == 1">建<em style="opacity: 0;">建</em><em style="opacity: 0;">议</em>议</span>
        <span slot="label" v-else>投<em style="opacity: 0;">投</em><em style="opacity: 0;">诉</em>诉</span>
        <el-col :span="12">
          <el-input type="textarea" v-model="appealForm.content" :placeholder="appealForm.type == 1 ? '请输入建议内容' : '请输入投诉内容'" :rows="6"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="onSubmitClick">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { userOpinion } from "@/api/system/user";

export default {
  props: {

  },
  data() {
    return {
      appealForm: {
        contact: '',
        type: "1",
        content: ''
      },
      appealRules: {
        content: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value == '' || !value) {
                if(this.appealForm.type == '1') {
                  callback(new Error('请输入建议内容'))
                } else {
                  callback(new Error('请输入投诉内容'))
                }
              } else {
                callback()
              }
            },
          }
        ]
      }
    }
  },
  computed: {

  },
  methods: {
    onSubmitClick() {
      this.$refs["appealForm"].validate(valid => {
        if (valid) {
          userOpinion(this.appealForm).then(response => {
            if (response.code == 200) {
              this.msgSuccess("保存成功")
              this.resetForm()
            } else {
              this.$message(response.msg)
              this.resetForm()
            }
          })
        }
      })
    },
    onAppealChange(value) {
      this.$refs['appealForm'].resetFields()
    },
    // 清空投诉、建议表单数据
    resetForm() {
      this.appealForm = {
        contact: '',
        type: "1",
        content: ''
      }
      if (this.$refs['appealForm']) {
        this.$refs['appealForm'].resetFields()
        // this.$refs['appealForm'].clearValidate()
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .app-user-appeal{
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
</style>
