var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "recycle-container" },
    [
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.recycleList } },
        [
          _c("el-table-column", {
            attrs: { label: "应用名称/表单名称", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == "1"
                      ? _c("span", { staticClass: "projectTag" }, [
                          _c("img", {
                            attrs: {
                              src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/project-icon.png",
                            },
                          }),
                        ])
                      : _c("span", { staticClass: "formTag" }, [
                          _c("img", {
                            attrs: {
                              src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-icon.png",
                            },
                          }),
                        ]),
                    _c("span", [_vm._v(_vm._s(scope.row.name))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "deleteTime", label: "删除时间", align: "center" },
          }),
          _c("el-table-column", {
            staticStyle: { height: "auto" },
            attrs: { label: "操作", width: "180", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onRestoreClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("还原")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          pageSizes: _vm.pageSizes,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          "update:pageSizes": function ($event) {
            _vm.pageSizes = $event
          },
          "update:page-sizes": function ($event) {
            _vm.pageSizes = $event
          },
          pagination: _vm.onTrashList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }