var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-user-agency" },
    [
      _c(
        "el-button",
        {
          staticClass: "fr",
          staticStyle: { "margin-bottom": "30px" },
          attrs: { type: "primary", size: "mini" },
          on: { click: _vm.onBindClick },
        },
        [_vm._v("绑定机构")]
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.deptData } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "agencyName",
              label: "机构名称",
              "min-width": "20%",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "姓名",
              "min-width": "25%",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "number",
              label: "编号",
              "min-width": "20%",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.number ? scope.row.number : "--")
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agencyRoleName",
              label: "所在部门",
              "min-width": "20%",
              align: "center",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.agencyUserDeptNames &&
                      scope.row.agencyUserDeptNames.length > 0
                      ? _c(
                          "div",
                          {},
                          _vm._l(
                            scope.row.agencyUserDeptNames,
                            function (deptItem, deptIndex) {
                              return _c("div", { key: deptIndex }, [
                                _c("span", [_vm._v(_vm._s(deptItem))]),
                                deptIndex <
                                scope.row.agencyUserDeptNames.length - 1
                                  ? _c("span", [_vm._v("、")])
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        )
                      : _c("div", [_c("div", [_vm._v("--")])])
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agencyRoleName",
              label: "系统角色",
              "min-width": "20%",
              align: "center",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.agencyUserRoleNames &&
                      scope.row.agencyUserRoleNames.length > 0
                      ? _c(
                          "div",
                          {},
                          _vm._l(
                            scope.row.agencyUserRoleNames,
                            function (roleItem, roleIndex) {
                              return _c("div", { key: roleIndex }, [
                                _c("span", [_vm._v(_vm._s(roleItem))]),
                                roleIndex <
                                scope.row.agencyUserRoleNames.length - 1
                                  ? _c("span", [_vm._v("、")])
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        )
                      : _c("div", [_c("div", [_vm._v("--")])])
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "绑定机构",
            visible: _vm.openBind,
            width: "500px",
            "append-to-body": "",
            "before-close": _vm.onHandleBindClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openBind = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "bindAgency",
              attrs: {
                model: _vm.agencyBind,
                rules: _vm.agencyBindRules,
                "label-width": "90px",
                "label-position": "right",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "value" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("机构名称"),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-autocomplete", {
                        staticClass: "inline-input",
                        staticStyle: { width: "100%" },
                        attrs: {
                          "fetch-suggestions": _vm.querySearch,
                          placeholder: "请输入机构名称",
                          "trigger-on-focus": false,
                        },
                        on: { select: _vm.handleSelect },
                        model: {
                          value: _vm.agencyBind.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.agencyBind, "value", $$v)
                          },
                          expression: "agencyBind.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.agencyBind.agencyId,
                      expression: "agencyBind.agencyId",
                    },
                  ],
                  attrs: { prop: "name" },
                },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("姓"),
                    _c("em", { staticStyle: { opacity: "0" } }, [_vm._v("姓")]),
                    _c("em", { staticStyle: { opacity: "0" } }, [_vm._v("名")]),
                    _vm._v("名"),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.agencyBind.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.agencyBind, "name", $$v)
                          },
                          expression: "agencyBind.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.agencyBind.agencyId,
                      expression: "agencyBind.agencyId",
                    },
                  ],
                  attrs: { label: "学号工号", prop: "number" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入学号或者工号" },
                        model: {
                          value: _vm.agencyBind.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.agencyBind, "number", $$v)
                          },
                          expression: "agencyBind.number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", [
                _c("p", [
                  _vm._v(
                    "注：管理员未导入学生教师信息前，无法进行绑定操作（校方机构未通知绑定，请勿进行绑定操作）"
                  ),
                ]),
                _c("p", [_vm._v("添加机构步骤：")]),
                _c("p", [_vm._v("1.学校机构管理员：后台导入学生教师信息。")]),
                _c("p", [
                  _vm._v(
                    "2.普通用户：a.搜索机构名称；b.输入正确的学号及姓名，完成绑定。"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.onSubmitClick },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.OnCancelClick } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }