var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-user-appeal" },
    [
      _c(
        "el-form",
        {
          ref: "appealForm",
          attrs: {
            model: _vm.appealForm,
            rules: _vm.appealRules,
            "label-width": "80px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c(
                  "em",
                  { staticStyle: { color: "#ff4949", "margin-right": "4px" } },
                  [_vm._v("*")]
                ),
                _vm._v("类"),
                _c("em", { staticStyle: { opacity: "0" } }, [_vm._v("类")]),
                _c("em", { staticStyle: { opacity: "0" } }, [_vm._v("型")]),
                _vm._v("型"),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.appealForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.appealForm, "type", $$v)
                        },
                        expression: "appealForm.type",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: "1" },
                          on: { change: _vm.onAppealChange },
                          model: {
                            value: _vm.appealForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.appealForm, "type", $$v)
                            },
                            expression: "appealForm.type",
                          },
                        },
                        [_vm._v("建议")]
                      ),
                      _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: "2" },
                          on: { change: _vm.onAppealChange },
                          model: {
                            value: _vm.appealForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.appealForm, "type", $$v)
                            },
                            expression: "appealForm.type",
                          },
                        },
                        [_vm._v("投诉")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系方式", prop: "contact" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入联系方式" },
                    model: {
                      value: _vm.appealForm.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.appealForm, "contact", $$v)
                      },
                      expression: "appealForm.contact",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "content" } },
            [
              _vm.appealForm.type == 1
                ? _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("建"),
                    _c("em", { staticStyle: { opacity: "0" } }, [_vm._v("建")]),
                    _c("em", { staticStyle: { opacity: "0" } }, [_vm._v("议")]),
                    _vm._v("议"),
                  ])
                : _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("投"),
                    _c("em", { staticStyle: { opacity: "0" } }, [_vm._v("投")]),
                    _c("em", { staticStyle: { opacity: "0" } }, [_vm._v("诉")]),
                    _vm._v("诉"),
                  ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder:
                        _vm.appealForm.type == 1
                          ? "请输入建议内容"
                          : "请输入投诉内容",
                      rows: 6,
                    },
                    model: {
                      value: _vm.appealForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.appealForm, "content", $$v)
                      },
                      expression: "appealForm.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.onSubmitClick },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }