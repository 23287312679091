<template>
  <div class="container">
    <header class="header-container">
      <div class="header-breadcrumb">
        <router-link class="private-home-link" to="/index">
          <span class="home-icon">
            <svg-icon icon-class="myHome" style="width: 18px; height: 18px; vertical-align: -2px;" />
          </span>
          <span>首页</span>
        </router-link>
        <svg-icon icon-class="myRightArrow" style="width: 18px; height: 18px; vertical-align:middle;" class="separator" />
        <span class="headerNow">个人中心</span>
      </div>
      <div class="header-actions">
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <img :src="userInfo.avatarUrl ? userInfo.avatarUrl + '?x-oss-process=image/resize,m_fixed,h_100,w_100' : 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/profile.jpg?x-oss-process=image/resize,m_fixed,h_100,w_100'" class="user-avatar">
            <i class="el-icon-caret-bottom" ></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/user/profile">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="logout">
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </header>
    <div class="clearfix app-container" style="margin-top:16px;">
      <div class="user-left fl">
        <ul class="tab-title">
          <li @click="onTabClick(1)" :class="{active:cur==1}">
            <svg-icon icon-class="myUserPerson" class="svgIcon" />
            个人信息
          </li>
          <li @click="onTabClick(5)" :class="{active:cur==5}">
            <svg-icon icon-class="myUserAccount" class="svgIcon" />
            账号安全
          </li>
          <li @click="onTabClick(2)" :class="{active:cur==2}">
            <svg-icon icon-class="myUserAgency" class="svgIcon" />
            所属机构
          </li>
          <li @click="onTabClick(7)" :class="{active:cur==7}">
            <svg-icon icon-class="myUserHistory" class="svgIcon" />
            浏览记录
          </li>
          <li @click="onTabClick(3)" :class="{active:cur==3}">
            <svg-icon icon-class="myUserCollect" class="svgIcon" />
            我的收藏
          </li>
          <li @click="onTabClick(4)" :class="{active:cur==4}">
            <svg-icon icon-class="myUserSuggest" class="svgIcon" />
            投诉建议
          </li>
          <li @click="onTabClick(6)" :class="{active:cur==6}">
            <svg-icon icon-class="myUserRecover" class="svgIcon" />
            我的回收
          </li>
        </ul>
      </div>
      <div style="width: 20px; flex-shrink: 0;"></div>
      <div class="user-right fl">
        <div class="tab-content" v-if="cur == 1">
          <userInfo />
        </div>
        <div class="tab-content" v-if="cur == 2">
          <userMechan :deptData="deptData" @refreshAgencyList="refreshAgencyList" />
        </div>
        <div class="tab-content" v-if="cur == 3">
          <userCollect :collectData="collectData" :total="total" @refreshData="refreshData" @getList="getCollectList" />
        </div>
        <div class="tab-content" v-if="cur == 4">
          <userAppeal />
        </div>
        <div class="tab-content" v-if="cur == 5">
          <pwdReset />
        </div>
        <div class="tab-content" v-if="cur == 6">
          <userRecycle />
        </div>
        <div class="tab-content" v-if="cur == 7">
          <userHistory />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userInfo from "./userInfo"
import userMechan from "./userMechan"
import userCollect from "./userCollect"
import userHistory from "./userHistory"
import userAppeal from "./userAppeal"
import pwdReset from "./pwdReset"
import userRecycle from "./userRecycle"
import { userAgencyMyList, userCollectList } from "@/api/system/user"
import { getDateDiff } from "@/utils/whale"
import { getSessionStorageObj, setSessionStorageObj } from "@/utils/db"
import { globalBus } from "@/utils/globalBus";

export default {
  name: "Profile",
  components: {
    userInfo,
    userMechan,
    userCollect,
    userHistory,
    userAppeal,
    pwdReset,
    userRecycle
  },
  data () {
    return {
      cur: 1,
      tabPosition: 'left',
      userInfo: {},
      deptData: [],
      collectData: [],
      total: 0,
    };
  },
  created () {
    this.getUserInfo()
    if (getSessionStorageObj('userCur')) {
      this.cur = getSessionStorageObj('userCur')
      if (this.cur == 2) {
        this.getuserAgencyMyList()
      }
      if (this.cur == 3) {
        this.getCollectList()
      }
    }
    // 在个人中心修改头像时 需要同步修改
    globalBus.$on('refreshUser', (data) => {
      this.getUserInfo()
    })
  },
  methods: {
    onTabClick (index) {
      this.cur = index
      if (this.cur == 2) {
        this.getuserAgencyMyList()
      }
      if (this.cur == 3) {
        this.getCollectList()
      }
      setSessionStorageObj('userCur', this.cur)
    },
    getUserInfo () {
      this.$store.dispatch("GetUserInfo").then((res) => {
        this.userInfo = res.data;
      });
    },
    logout () {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.reload();
        })
      })
    },
    //获取用户所属机构列表
    getuserAgencyMyList () {
      userAgencyMyList().then(response => {
        if (response.code == 200) {
          console.log(response)
          this.deptData = response.data
        } else {
          this.msgError(response.msg);
        }
      })
    },
    //获取用户收藏列表
    getCollectList (data) {
      let queryParams = {
        pageNum: data && data.page ? data.page : 1,
        pageSize: data && data.limit ? data.limit : 5
      }
      userCollectList(queryParams).then(response => {
        if (response.code == 200) {
          for (let i = 0; i < response.rows.length; i++) {
            response.rows[i].showBtn = false
            let formStateTxt = '' //表单状态（未开始、进行中、已结束）
            let curTime = response.now
            if (response.rows[i].type == 'form') {
              if (response.rows[i].form.status == '1') {
                formStateTxt = '未发布'
              } else {
                if (!this.isBlank(response.rows[i].form.fillStartTime) && !this.isBlank(response.rows[i].form.fillEndTime)) {
                  if (getDateDiff(response.rows[i].form.fillStartTime, curTime)) {
                    formStateTxt = '未开始'
                  } else if (!getDateDiff(response.rows[i].form.fillStartTime, curTime) && !getDateDiff(response.rows[i].form.fillEndTime, curTime)) {
                    formStateTxt = '已结束'
                  } else {
                    formStateTxt = '进行中'
                  }
                } else {
                  formStateTxt = '进行中'
                }
              }
              response.rows[i].form.formStateTxt = formStateTxt
            }
          }
          this.collectData = response.rows
          this.total = response.total
        } else {
          this.$message(response.msg)
        }
      })
    },
    //子组件取消收藏刷新父组件数据
    refreshData (flag) {
      if (flag) {
        this.getCollectList()
      }
    },
    //子组件绑定机构成功刷新父组件数据
    refreshAgencyList (flag) {
      if (flag) {
        this.getuserAgencyMyList()
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.header-container {
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
  font-size: 13px;
  height: 55px;
  color: #2b3441;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);
  padding: 0 30px;
  .header-breadcrumb {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    .private-home-link {
      cursor: pointer;
      color: rgba(43, 52, 65, 0.6);
      font-size: 18px;
      font-weight: 500;
      .home-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-right: 8px;
        border-radius: 50%;
        background: #f5f5f7;
      }
    }
    .separator {
      margin: 0 11px 0 17px;
    }
    .headerNow {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .header-actions {
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    .avatar-container {
      height: 36px;
      .avatar-wrapper {
        height: 36px;
        position: relative;
        .user-avatar {
          cursor: pointer;
          width: 36px;
          height: 36px;
          border-radius: 18px;
        }
        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -18px;
          top: 12px;
          font-size: 12px;
        }
      }
    }
  }
}
.user-left {
  width: 240px;
  .tab-title {
    margin: 0;
    padding: 20px 16px;
    ul {
      padding: 16px;
    }
    li {
      width: 100%;
      text-align: center;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      overflow: hidden;
      margin: 0 0 10px;
      list-style: none;
      font-size: 14px;
      color: #333333;
      background: #a5abb10d;
      border-radius: 4px;
      font-weight: 500 !important;
      text-align: left;
      &.active {
        background: #3476F014;
        color: #3476F0;
        position: relative;
        border-radius: 6px;
        font-weight: 500 !important;
        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 30px;
          left: 0;
          top: 50%;
          margin-top: -15px;
          background: #3476F0;
          opacity: 0.4;
          border-radius: 0 3px 3px 0;
        }
        .svgIcon{
          fill:#3476F0;
        }
      }

      &:hover {
        background: #3476F014;
        color: #3476F0;
        border-radius: 6px;
        font-weight: 500 !important;
      }
    }
  }
}
.user-right {
  padding: 0;
  min-height: 220px;
  box-sizing: border-box;
  flex: 1;
  -webkit-flex: 1;
  .tab-content {
    width: 100%;
    height: 100%;
  }
}

.user-left {
  background-color: white;
  border-radius: 4px;
}
.user-right {
  background-color: white;
  border-radius: 4px;
}

.anim-fade-in {
  -webkit-animation: 1s slideLeftActive;
  animation: 1s slideLeftActive;
}
@-webkit-keyframes slideLeftActive {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideLeftActive {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.svgIcon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-right: 8px;
  color: #5e6d82;
  vertical-align: -5px;
}
</style>
