var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-user-collect" },
    [
      _vm.collectData.length > 0
        ? _c(
            "div",
            { staticClass: "list-content" },
            [
              _vm._l(_vm.collectData, function (item) {
                return [
                  item.type == "form"
                    ? _c(
                        "el-row",
                        {
                          staticClass: "el-row",
                          staticStyle: {
                            margin: "0",
                            padding: "20px 0",
                            "border-bottom": "1px solid #E5E5E5",
                            "box-sizing": "border-box",
                          },
                          attrs: { gutter: 20, type: "flex" },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                padding: "0",
                                width: "200px",
                                height: "120px",
                              },
                              attrs: { span: 4, xs: 24 },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "form-img",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetailClick(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        item.form && item.form.cover
                                          ? item.form.cover +
                                            "?x-oss-process=image/resize,m_fixed,h_150,w_300"
                                          : _vm.defaultHeadImg +
                                            "?x-oss-process=image/resize,m_fixed,h_150,w_300",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-left": "0",
                                "padding-right": "0",
                                "margin-left": "30px",
                                cursor: "pointer",
                              },
                              attrs: { span: 20, xs: 24 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-info",
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.onFormMouseEnter(item)
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.onFormMouseLeave(
                                        item,
                                        _vm.index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix form-title" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "title-span",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDetailClick(item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.form.name))]
                                      ),
                                      _c(
                                        "el-tag",
                                        {
                                          staticClass: "form-tag",
                                          attrs: {
                                            size: "mini",
                                            type:
                                              item.form.formStateTxt != "进行中"
                                                ? "info"
                                                : "",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.form.formStateTxt))]
                                      ),
                                      _c("div", { staticClass: "fr" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-collect",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onCollectClick(
                                                  item,
                                                  _vm.index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  round: "",
                                                  size: "mini",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "collect-span active",
                                                  },
                                                  [_vm._v("收藏")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-date",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDetailClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "fill-date" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              width: "50px",
                                              display: "inline-block",
                                            },
                                          },
                                          [_vm._v("创建时间")]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v(":")]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.form.createTime)),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  item.form.fillStartTime ||
                                  item.form.fillEndTime ||
                                  (item.form.fillStartTime &&
                                    item.form.fillEndTime)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "clearfix form-date",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDetailClick(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "fill-date" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    width: "50px",
                                                    display: "inline-block",
                                                  },
                                                },
                                                [_vm._v("填报时间")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    margin: "0 10px",
                                                  },
                                                },
                                                [_vm._v(":")]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.form.fillStartTime
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    margin: "0 5px",
                                                  },
                                                },
                                                [_vm._v(" - ")]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.form.fillEndTime)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !item.form.fillStartTime &&
                                  !item.form.fillEndTime
                                    ? _c(
                                        "div",
                                        { staticClass: "clearfix form-date" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "fill-date" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    width: "50px",
                                                    display: "inline-block",
                                                  },
                                                },
                                                [_vm._v("填报时间")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    margin: "0 10px",
                                                  },
                                                },
                                                [_vm._v(":")]
                                              ),
                                              _c("span", [_vm._v("——")]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix form-date",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDetailClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "fill-date" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              width: "50px",
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                              },
                                              [_vm._v("创")]
                                            ),
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  "font-style": "normal",
                                                  margin: "0 6px",
                                                },
                                              },
                                              [_vm._v("建")]
                                            ),
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                              },
                                              [_vm._v("人")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v(":")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.form.founder
                                                ? item.form.founder
                                                : item.form.createName
                                            )
                                          ),
                                        ]),
                                        item.form.agencyId
                                          ? _c("img", {
                                              staticClass: "is-agency-icon",
                                              attrs: {
                                                src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                              },
                                            })
                                          : _c("span", [_vm._v("（个人）")]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix form-date",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDetailClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "fill-date fl" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              width: "50px",
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _vm._v("类"),
                                            _c(
                                              "i",
                                              { staticStyle: { opacity: "0" } },
                                              [_vm._v("类型")]
                                            ),
                                            _vm._v("型"),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v(":")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.type == "project"
                                                ? "应用"
                                                : "表单"
                                            )
                                          ),
                                        ]),
                                        _c("img", {
                                          staticClass: "is-form-icon",
                                          attrs: {
                                            src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-icon.png",
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type == "project"
                    ? _c(
                        "el-row",
                        {
                          staticClass: "el-row",
                          staticStyle: {
                            margin: "0",
                            padding: "20px 0",
                            "border-bottom": "1px solid #E5E5E5",
                            "box-sizing": "border-box",
                            "align-items": "center",
                          },
                          attrs: { gutter: 20, type: "flex" },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                padding: "0",
                                width: "200px",
                                height: "120px",
                              },
                              attrs: { span: 4, xs: 24 },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "form-img",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetailClick(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        item.project && item.project.cover
                                          ? item.project.cover +
                                            "?x-oss-process=image/resize,m_fixed,h_150,w_300"
                                          : _vm.defaultHeadImg +
                                            "?x-oss-process=image/resize,m_fixed,h_150,w_300",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-left": "0",
                                "padding-right": "0",
                                "margin-left": "30px",
                                cursor: "pointer",
                              },
                              attrs: { span: 20, xs: 24 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-info",
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.onFormMouseEnter(item)
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.onFormMouseLeave(
                                        item,
                                        _vm.index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix form-title" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "title-span",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDetailClick(item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.project.name))]
                                      ),
                                      _c("div", { staticClass: "fr" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-collect",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onCollectClick(
                                                  item,
                                                  _vm.index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  round: "",
                                                  size: "mini",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "collect-span active",
                                                  },
                                                  [_vm._v("收藏")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-date",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDetailClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "fill-date" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              width: "50px",
                                              display: "inline-block",
                                            },
                                          },
                                          [_vm._v("创建时间")]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v(":")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.project.createTime)
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix form-date",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDetailClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "fill-date" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              width: "50px",
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                              },
                                              [_vm._v("创")]
                                            ),
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  "font-style": "normal",
                                                  margin: "0 6px",
                                                },
                                              },
                                              [_vm._v("建")]
                                            ),
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                              },
                                              [_vm._v("人")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v(":")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.project.createName)
                                          ),
                                        ]),
                                        item.project.agencyId
                                          ? _c("img", {
                                              staticClass: "is-agency-icon",
                                              attrs: {
                                                src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                              },
                                            })
                                          : _c("span", [_vm._v("（个人）")]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix form-date",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDetailClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "fill-date fl" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              width: "50px",
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _vm._v("类"),
                                            _c(
                                              "i",
                                              { staticStyle: { opacity: "0" } },
                                              [_vm._v("类型")]
                                            ),
                                            _vm._v("型"),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v(":")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.type == "project"
                                                ? "应用"
                                                : "表单"
                                            )
                                          ),
                                        ]),
                                        _c("img", {
                                          staticClass: "is-project-icon",
                                          attrs: {
                                            src:
                                              item.type == "project"
                                                ? "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/project-icon.png"
                                                : "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-icon.png",
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _c("div", { staticClass: "list-no-data" }, [
            _c("img", {
              staticClass: "no-data-img",
              attrs: {
                src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collect_no.jpg",
              },
            }),
            _c("span", { staticClass: "no-data-text" }, [_vm._v("暂无收藏~")]),
          ]),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          pageSizes: _vm.pageSizes,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          "update:pageSizes": function ($event) {
            _vm.pageSizes = $event
          },
          "update:page-sizes": function ($event) {
            _vm.pageSizes = $event
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "填报地址",
            visible: _vm.shareOpen,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.shareOpen = $event
            },
            close: _vm.handleFillDiaClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "share-container" },
            [
              _c("p", [
                _vm._v("将填报地址粘贴到浏览器访问，或者直接发给填报者"),
              ]),
              _c("el-input", {
                attrs: { type: "text", value: _vm.shareFillUrl, readonly: "" },
              }),
              _c(
                "p",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onCopyFillClick },
                    },
                    [_vm._v("复制链接")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.onToClick },
                    },
                    [_vm._v("直接打开")]
                  ),
                ],
                1
              ),
              _vm.isShall == 1
                ? _c(
                    "div",
                    { ref: "imageToFile", staticClass: "share-lite-contain" },
                    [
                      _c("div", { staticClass: "top-logo" }, [
                        _c("img", {
                          attrs: {
                            src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/pc_logo_white.png",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "share-card" }, [
                        _c("img", {
                          staticClass: "share-form-img",
                          attrs: { src: _vm.shareItemInfo.headImg },
                        }),
                        _c("div", { staticClass: "share-form-name" }, [
                          _vm._v(_vm._s(_vm.shareItemInfo.name)),
                        ]),
                        _vm.shareItemInfo.fillStartTime ||
                        _vm.shareItemInfo.fillEndTime ||
                        (_vm.shareItemInfo.fillStartTime &&
                          _vm.shareItemInfo.fillEndTime)
                          ? _c("div", { staticClass: "share-form-fill" }, [
                              _vm.shareItemInfo.fillStartTime
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.shareItemInfo.fillStartTime)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [_vm._v("-")]),
                              _vm.shareItemInfo.fillEndTime
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.shareItemInfo.fillEndTime)
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "share-form-bottom" }, [
                          _c("img", {
                            staticClass: "fl share-form-qr",
                            attrs: { src: _vm.shareItemInfo.shareQr },
                          }),
                          _c("div", { staticClass: "share-form-qr-text" }, [
                            _c("span", [_vm._v("长按或扫描识别小程序码")]),
                            _c("span", [_vm._v("开始填报")]),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isShall == 2
                ? _c("div", { staticClass: "share-lite-contain" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.shallImg, alt: "" },
                    }),
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onSaveClick },
                },
                [_vm._v("保存小程序码")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "移动表单",
            visible: _vm.formMoveVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formMoveVisible = $event
            },
            close: _vm.onCloseMoveClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formMove",
              attrs: {
                model: _vm.formMoveObj,
                rules: _vm.formMoveRules,
                "label-width": "90px",
                "label-position": "right",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "移动应用", prop: "projectId" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择移动应用",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formMoveObj.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formMoveObj, "projectId", $$v)
                            },
                            expression: "formMoveObj.projectId",
                          },
                        },
                        _vm._l(_vm.moveFormProjectList, function (item) {
                          return _c("el-option", {
                            key: item.projectId,
                            attrs: { label: item.name, value: item.projectId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmMoveClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelMoveClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }