var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix app-pwd-container" },
    [
      _c("div", { staticClass: "team-container" }, [
        _c("div", { staticClass: "team-info-detail" }, [
          _c("div", { staticClass: "fx-row-info-panel" }, [
            _c("div", { staticClass: "label" }, [_vm._v("手机")]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm.userInfo.mobile
                  ? _c("span", { staticClass: "content-row" }, [
                      _vm._v(_vm._s(_vm.userInfo.mobile)),
                    ])
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "x-button",
                    attrs: { type: "text" },
                    on: { click: _vm.onUserPhoneClick },
                  },
                  [_vm._v(_vm._s(_vm.userInfo.mobile ? "更换" : "绑定"))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "fx-row-info-panel" }, [
            _c("div", { staticClass: "label" }, [_vm._v("邮箱")]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm.userInfo.email
                  ? _c("span", { staticClass: "content-row" }, [
                      _vm._v(_vm._s(_vm.userInfo.email)),
                    ])
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "x-button",
                    style: { padding: _vm.userInfo.email ? "0 20px" : "0 0" },
                    attrs: { type: "text" },
                    on: { click: _vm.onUserEmailClick },
                  },
                  [_vm._v(_vm._s(_vm.userInfo.email ? "更换" : "绑定"))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "fx-row-info-panel" }, [
            _c("div", { staticClass: "label" }, [_vm._v("密码")]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "x-button",
                    staticStyle: { padding: "0 20px 0 0" },
                    attrs: { type: "text" },
                    on: { click: _vm.onEditUserPwd },
                  },
                  [_vm._v("修改")]
                ),
                _vm.userInfo.mobile
                  ? _c(
                      "el-button",
                      {
                        staticClass: "x-button",
                        staticStyle: { padding: "0 0", "margin-left": "0" },
                        attrs: { type: "text" },
                        on: { click: _vm.onResetUserPwd },
                      },
                      [_vm._v("重置")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.bindOpen,
            width: "450px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bindOpen = $event
            },
            open: _vm.onOpenBind,
            close: _vm.onCloseBind,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "bindForm",
              attrs: {
                type: "flex",
                model: _vm.bindForm,
                rules: _vm.bindFormRules,
                "label-position": "left",
                "label-width": "70px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "username",
                  attrs: {
                    prop: "username",
                    label: _vm.bindForm.type == "phone" ? "手机号" : "邮箱",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "off",
                      placeholder:
                        _vm.bindForm.type == "phone"
                          ? "请输入绑定手机号"
                          : "请输入绑定邮箱",
                    },
                    model: {
                      value: _vm.bindForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.bindForm, "username", $$v)
                      },
                      expression: "bindForm.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { ref: "code", attrs: { prop: "code", label: "验证码" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "calc(100% - 120px)" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请输入验证码",
                    },
                    model: {
                      value: _vm.bindForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.bindForm, "code", $$v)
                      },
                      expression: "bindForm.code",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "fr send-code" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isGetCode,
                              expression: "isGetCode",
                            },
                          ],
                          staticStyle: {
                            width: "100px",
                            padding: "10px 0",
                            height: "32px",
                            "margin-top": "2px",
                            "line-height": "10px",
                            "box-sizing": "border-box",
                          },
                          attrs: { plain: "", size: "medium", type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onCodeClick($event)
                            },
                          },
                        },
                        [_vm._v("获取验证码")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isGetCode,
                              expression: "!isGetCode",
                            },
                          ],
                          staticStyle: {
                            width: "100px",
                            padding: "10px 0",
                            height: "32px",
                            "margin-top": "2px",
                            "line-height": "10px",
                            "box-sizing": "border-box",
                          },
                          attrs: {
                            plain: "",
                            size: "medium",
                            type: "primary",
                            disabled: (_vm.disabled = !_vm.isGetCode),
                          },
                        },
                        [_vm._v(_vm._s(_vm.bindCount) + "s后重试")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                width: "50%",
                "margin-top": "-30px",
                "margin-left": "auto",
                "margin-right": "auto",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleBind($event)
                    },
                  },
                },
                [_vm._v("确认绑定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.updateBindOpen,
            width: "450px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.updateBindOpen = $event
            },
            open: _vm.onUpdateBindOpen,
            close: _vm.onUpdateBindClose,
          },
        },
        [
          _vm.updateBindStep == 1
            ? [
                _c(
                  "div",
                  { staticClass: "VerificationDialogModalHeader" },
                  [
                    _c(
                      "h1",
                      { staticClass: "VerificationDialogModalHeader-title" },
                      [_vm._v("身份验证")]
                    ),
                    _c(
                      "h2",
                      { staticClass: "VerificationDialogModalHeader-subTitle" },
                      [
                        _vm._v(
                          "为了保护你的帐号安全，请验证身份，验证成功后进行下一步操作"
                        ),
                      ]
                    ),
                    _vm.updateBindForm.type == "phone"
                      ? _c(
                          "div",
                          {
                            staticClass: "VerificationDialogModal-oneSelection",
                          },
                          [
                            _vm._v(
                              "使用手机" + _vm._s(_vm.userInfo.mobile) + "验证"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.updateBindForm.type == "email"
                      ? _c(
                          "div",
                          {
                            staticClass: "VerificationDialogModal-oneSelection",
                          },
                          [
                            _vm._v(
                              "使用邮箱" + _vm._s(_vm.userInfo.email) + "验证"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-form",
                      {
                        ref: "updateBindForm",
                        attrs: {
                          type: "flex",
                          model: _vm.updateBindForm,
                          rules: _vm.updateBindFormRules,
                          "label-position": "left",
                          "label-width": "70px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "changeBindingCode",
                            attrs: {
                              prop: "changeBindingCode",
                              label: "验证码",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "calc(100% - 120px)" },
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "请输入验证码",
                              },
                              model: {
                                value: _vm.updateBindForm.changeBindingCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.updateBindForm,
                                    "changeBindingCode",
                                    $$v
                                  )
                                },
                                expression: "updateBindForm.changeBindingCode",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "fr send-code" },
                              [
                                _vm.isGetUpdateBindCode
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          padding: "10px 0",
                                          height: "32px",
                                          "margin-top": "2px",
                                          "line-height": "10px",
                                          "box-sizing": "border-box",
                                        },
                                        attrs: {
                                          plain: "",
                                          size: "medium",
                                          type: "primary",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.onOriginalCodeClick(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("获取验证码")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          padding: "10px 0",
                                          height: "32px",
                                          "margin-top": "2px",
                                          "line-height": "10px",
                                          "box-sizing": "border-box",
                                        },
                                        attrs: {
                                          plain: "",
                                          size: "medium",
                                          type: "primary",
                                          disabled: _vm.isGetUpdateBindCode
                                            ? false
                                            : true,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.updateBindCount) +
                                            "s后重试"
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          width: "50%",
                          "margin-left": "auto",
                          "margin-right": "auto",
                        },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleCheckout($event)
                              },
                            },
                          },
                          [_vm._v("验证")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.updateBindStep == 2
            ? [
                _c(
                  "el-form",
                  {
                    ref: "updateBindForm",
                    attrs: {
                      type: "flex",
                      model: _vm.updateBindForm,
                      rules: _vm.updateBindFormRules,
                      "label-position": "left",
                      "label-width": "70px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        ref: "username",
                        attrs: {
                          prop: "username",
                          label:
                            _vm.updateBindForm.type == "phone"
                              ? "手机号"
                              : "邮箱",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "text",
                            "auto-complete": "off",
                            placeholder:
                              _vm.updateBindForm.type == "phone"
                                ? "请输入绑定手机号"
                                : "请输入绑定邮箱",
                          },
                          model: {
                            value: _vm.updateBindForm.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.updateBindForm, "username", $$v)
                            },
                            expression: "updateBindForm.username",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { ref: "code", attrs: { prop: "code", label: "验证码" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "calc(100% - 120px)" },
                          attrs: {
                            "auto-complete": "off",
                            placeholder: "请输入验证码",
                          },
                          model: {
                            value: _vm.updateBindForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.updateBindForm, "code", $$v)
                            },
                            expression: "updateBindForm.code",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "fr send-code" },
                          [
                            _vm.isGetNewCode
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      padding: "10px 0",
                                      height: "32px",
                                      "margin-top": "2px",
                                      "line-height": "10px",
                                      "box-sizing": "border-box",
                                    },
                                    attrs: {
                                      plain: "",
                                      size: "medium",
                                      type: "primary",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onNewCodeClick($event)
                                      },
                                    },
                                  },
                                  [_vm._v("获取验证码")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      padding: "10px 0",
                                      height: "32px",
                                      "margin-top": "2px",
                                      "line-height": "10px",
                                      "box-sizing": "border-box",
                                    },
                                    attrs: {
                                      plain: "",
                                      size: "medium",
                                      type: "primary",
                                      disabled: _vm.isGetNewCode ? false : true,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.bindNewCount) + "s后重试")]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      width: "50%",
                      "margin-left": "auto",
                      "margin-right": "auto",
                    },
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { type: "primary" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onUpdateConfirmBind($event)
                          },
                        },
                      },
                      [_vm._v("确认绑定")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.editPwdOpen,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPwdOpen = $event
            },
            open: _vm.onOpenEdit,
            close: _vm.onCloseEdit,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPwdForm",
              attrs: {
                type: "flex",
                model: _vm.editPwdForm,
                rules: _vm.editPwdFormRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { label: "旧密码", prop: "oldPassword" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "off",
                      placeholder: "请输入原密码",
                    },
                    model: {
                      value: _vm.editPwdForm.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPwdForm, "oldPassword", $$v)
                      },
                      expression: "editPwdForm.oldPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { label: "新密码", prop: "password" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "off",
                      placeholder: "请输入新密码",
                    },
                    model: {
                      value: _vm.editPwdForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPwdForm, "password", $$v)
                      },
                      expression: "editPwdForm.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { label: "确认密码", prop: "confirmPassword" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "off",
                      placeholder: "请确认密码",
                    },
                    model: {
                      value: _vm.editPwdForm.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPwdForm, "confirmPassword", $$v)
                      },
                      expression: "editPwdForm.confirmPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmEditPwd },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelEditPwd } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "重置密码",
            visible: _vm.resetPwdOpen,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.resetPwdOpen = $event
            },
            open: _vm.onOpenReset,
            close: _vm.onCloseReset,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "forgetPwdForm",
              attrs: {
                model: _vm.forgetPwdForm,
                rules: _vm.forgetPwdFormRules,
              },
            },
            [
              _c(
                "div",
                { staticClass: "VerificationDialogModal-oneSelection" },
                [_vm._v("使用手机" + _vm._s(_vm.userInfo.mobile) + "重置密码")]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { prop: "code" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "calc(100% - 120px)" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请输入手机验证码",
                    },
                    model: {
                      value: _vm.forgetPwdForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.forgetPwdForm, "code", $$v)
                      },
                      expression: "forgetPwdForm.code",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "fr send-code" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isGetForgetPwdCode,
                              expression: "isGetForgetPwdCode",
                            },
                          ],
                          staticStyle: {
                            width: "100px",
                            padding: "10px 0",
                            height: "32px",
                            "margin-top": "2px",
                            "line-height": "10px",
                            "box-sizing": "border-box",
                          },
                          attrs: { plain: "", size: "medium", type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.getForgetPwdCode($event)
                            },
                          },
                        },
                        [_vm._v("获取验证码")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isGetForgetPwdCode,
                              expression: "!isGetForgetPwdCode",
                            },
                          ],
                          staticStyle: {
                            width: "100px",
                            padding: "10px 0",
                            height: "32px",
                            "margin-top": "2px",
                            "line-height": "10px",
                            "box-sizing": "border-box",
                          },
                          attrs: {
                            plain: "",
                            size: "medium",
                            type: "primary",
                            disabled: (_vm.disabled = !_vm.isGetForgetPwdCode),
                          },
                        },
                        [_vm._v(_vm._s(_vm.forgetPwdCount) + "s后重试")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { prop: "password" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "off",
                      placeholder: "请重新输入密码",
                    },
                    model: {
                      value: _vm.forgetPwdForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.forgetPwdForm, "password", $$v)
                      },
                      expression: "forgetPwdForm.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { prop: "confirmPassword" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "off",
                      placeholder: "请再次输入密码",
                    },
                    model: {
                      value: _vm.forgetPwdForm.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.forgetPwdForm, "confirmPassword", $$v)
                      },
                      expression: "forgetPwdForm.confirmPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmResetPwd },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelResetPwd } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }