var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-user-info" }, [
    _c("div", { staticClass: "team-container" }, [
      _c(
        "div",
        { staticClass: "team-info-detail" },
        [
          _c("div", { staticClass: "fx-row-info-panel" }, [
            _c("div", { staticClass: "label" }, [_vm._v("用户头像")]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "div",
                  {
                    staticClass: "headPortrait-group--data",
                    on: { click: _vm.editCropper },
                  },
                  [
                    _c("div", { staticClass: "headPortrait-group--hover" }),
                    _c("img", {
                      staticClass: "img-circle img-lg",
                      staticStyle: { width: "60px", height: "60px" },
                      attrs: {
                        src: _vm.option.img
                          ? _vm.option.img
                          : "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/profile.jpg",
                        title: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.title,
                      visible: _vm.open,
                      width: "800px",
                      "append-to-body": "",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.open = $event
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "cropper-content" }, [
                      _c("div", { staticClass: "cropper-box" }, [
                        _c(
                          "div",
                          { staticClass: "cropper" },
                          [
                            _c("vue-cropper", {
                              ref: "cropper",
                              attrs: {
                                img: _vm.option.img,
                                outputSize: _vm.option.outputSize,
                                outputType: _vm.option.outputType,
                                info: _vm.option.info,
                                canScale: _vm.option.canScale,
                                autoCrop: _vm.option.autoCrop,
                                autoCropWidth: _vm.option.autoCropWidth,
                                autoCropHeight: _vm.option.autoCropHeight,
                                fixed: _vm.option.fixed,
                                fixedNumber: _vm.option.fixedNumber,
                                full: _vm.option.full,
                                fixedBox: _vm.option.fixedBox,
                                canMove: _vm.option.canMove,
                                canMoveBox: _vm.option.canMoveBox,
                                original: _vm.option.original,
                                centerBox: _vm.option.centerBox,
                                height: _vm.option.height,
                                infoTrue: _vm.option.infoTrue,
                                maxImgSize: _vm.option.maxImgSize,
                                enlarge: _vm.option.enlarge,
                                mode: _vm.option.mode,
                              },
                              on: {
                                realTime: _vm.realTime,
                                imgLoad: _vm.imgLoad,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "footer-btn" }, [
                          _c(
                            "div",
                            { staticClass: "scope-btn" },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: "#",
                                    "http-request": _vm.requestUpload,
                                    "show-file-list": false,
                                    "before-upload": _vm.beforeUpload,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: { size: "small" },
                                      on: { click: _vm.initConfig },
                                    },
                                    [
                                      _vm._v("上传"),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-upload el-icon--right",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    plain: "",
                                    icon: "el-icon-zoom-in",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeScale(1)
                                    },
                                  },
                                },
                                [_vm._v("放大")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    plain: "",
                                    icon: "el-icon-zoom-out",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeScale(-1)
                                    },
                                  },
                                },
                                [_vm._v("缩小")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    plain: "",
                                  },
                                  on: { click: _vm.rotateLeft },
                                },
                                [_vm._v("↺ 左旋转")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    plain: "",
                                  },
                                  on: { click: _vm.rotateRight },
                                },
                                [_vm._v("↻ 右旋转")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "upload-btn" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "success" },
                                  on: { click: _vm.uploadImg },
                                },
                                [_vm._v("确定上传")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "show-preview" }, [
                        _c("div", { staticClass: "preview" }, [
                          _c("img", {
                            style: _vm.previews.img,
                            attrs: { src: _vm.previews.url },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm.agencyUserData
            ? _c("div", { staticClass: "fx-row-info-panel" }, [
                _c("div", { staticClass: "label" }, [_vm._v("姓名")]),
                _c("div", { staticClass: "content" }, [
                  _c("span", { staticClass: "content-row" }, [
                    _vm._v(
                      _vm._s(
                        _vm.agencyUserData.name
                          ? _vm.agencyUserData.name
                          : "暂未设置"
                      )
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.agencyUserData
            ? _c("div", { staticClass: "fx-row-info-panel" }, [
                _c("div", { staticClass: "label" }, [_vm._v("编号")]),
                _c("div", { staticClass: "content" }, [
                  _c("span", { staticClass: "content-row" }, [
                    _vm._v(
                      _vm._s(
                        _vm.agencyUserData.number
                          ? _vm.agencyUserData.number
                          : "暂未设置"
                      )
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.agencyUserData.agencyUserDeptNames
            ? _c("div", { staticClass: "fx-row-info-panel" }, [
                _c("div", { staticClass: "label" }, [_vm._v("所在部门")]),
                _c("div", { staticClass: "content" }, [
                  _c("span", { staticClass: "content-row" }, [
                    _vm._v(
                      _vm._s(_vm.agencyUserData.agencyUserDeptNames.join())
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.agencyUserData.agencyUserRoleNames
            ? _c("div", { staticClass: "fx-row-info-panel" }, [
                _c("div", { staticClass: "label" }, [_vm._v("角色")]),
                _c("div", { staticClass: "content" }, [
                  _c("span", { staticClass: "content-row" }, [
                    _vm._v(
                      _vm._s(_vm.agencyUserData.agencyUserRoleNames.join())
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          !_vm.agencyUserData
            ? _c("div", { staticClass: "fx-row-info-panel" }, [
                _c("div", { staticClass: "label" }, [_vm._v("昵称")]),
                _c("div", { staticClass: "content" }, [
                  _c("span", { staticClass: "content-row" }, [
                    _vm._v(
                      _vm._s(
                        _vm.userInfo.nickName
                          ? _vm.userInfo.nickName
                          : "暂未设置"
                      )
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "fx-row-info-panel" }, [
            _c("div", { staticClass: "label" }, [_vm._v("登录账号")]),
            _c("div", { staticClass: "content" }, [
              _c("span", { staticClass: "content-row" }, [
                _vm._v(
                  _vm._s(
                    _vm.userInfo.mobile
                      ? _vm.userInfo.mobile
                      : _vm.userInfo.email
                      ? _vm.userInfo.email
                      : _vm.userInfo.nickName
                  )
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "fx-row-info-panel" }, [
            _c("div", { staticClass: "label" }, [_vm._v("账号类型")]),
            _c("div", { staticClass: "content" }, [
              _c("span", { staticClass: "content-row" }, [
                _vm._v(
                  _vm._s(
                    _vm.userInfo.type == "1"
                      ? "普通用户"
                      : _vm.userInfo.type == "2"
                      ? "机构管理员"
                      : "机构子管理员"
                  )
                ),
              ]),
            ]),
          ]),
          _vm._l(_vm.userExtendProfile, function (widget, index) {
            return _c(
              "div",
              { staticClass: "fx-row-info-panel", attrs: { index: index } },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(widget.options.label)),
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("span", { staticClass: "content-row" }, [
                      _vm._v(
                        _vm._s(
                          _vm.agencyUserData.extendField[widget.options.name]
                        )
                      ),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "x-button",
                        attrs: { type: "text" },
                        on: { click: _vm.onEditUserInfo },
                      },
                      [_vm._v("修改")]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改信息",
                visible: _vm.infoOpen,
                width: "640px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.infoOpen = $event
                },
                close: _vm.onCloseEdit,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "projectForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "120px",
                    "label-position": "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户 UID", prop: "nickName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入用户 UID", disabled: "" },
                        model: {
                          value: _vm.form.uid,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "uid", $$v)
                          },
                          expression: "form.uid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sex", $$v)
                            },
                            expression: "form.sex",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.form.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sex", $$v)
                                },
                                expression: "form.sex",
                              },
                            },
                            [_vm._v("男")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              model: {
                                value: _vm.form.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sex", $$v)
                                },
                                expression: "form.sex",
                              },
                            },
                            [_vm._v("女")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录账号", prop: "nickName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入登录账号", disabled: "" },
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号类型", prop: "nickName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入账号类型", disabled: "" },
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "user-extend-field-wrapper" },
                    [
                      _c("v-form-render", {
                        ref: "vformRender",
                        attrs: {
                          formJson: _vm.formJson,
                          formData: _vm.formData,
                          optionData: _vm.optionData,
                          previewState: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onConfirmEdit },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.onCancelEdit } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "绑定微信",
                visible: _vm.wechatBindOpen,
                width: "640px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.wechatBindOpen = $event
                },
                close: _vm.onCloseWeChatBind,
              },
            },
            [
              _c("wechatBind"),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onConfirmEdit },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.onCloseWeChatBind } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }